import React from 'react';
import * as CustomCode from '../CustomCode';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, Modal, StatusBar, Text, View } from 'react-native';

const BookingEstimatesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [VisuMenu, setVisuMenu] = React.useState(false);
  const [arrivalDate, setArrivalDate] = React.useState('');
  const [departureDate, setDepartureDate] = React.useState('');
  const [duePrice, setDuePrice] = React.useState(0);
  const [estimatedPrice, setEstimatedPrice] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [totalNights, setTotalNights] = React.useState('');
  const [totalPersons, setTotalPersons] = React.useState('');
  const assignGlblVars = (Variables, setGlobalVariableValue) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    //console.log("CalcBookingPrice::Global Persons number ==> "+Variables.glblPersonsBooked);
    console.log(
      'Variables.glblStartOfBooking ==> ' + Variables.glblStartOfBooking
    );
    setArrivalDate(Variables.glblStartOfBooking);
    setDepartureDate(Variables.glblEndOfBooking);

    let _totPersons = Variables.glblPersonsBooked; //props.route.params.totPersons;//
    setTotalPersons(_totPersons + ' persons'); //alert(totPersons);
    let _totNights = Variables.glblNightsBooked; //props.route.params.totNights;//
    setTotalNights(_totNights + ' nights');

    console.log('Global Persons number ==> ' + Variables.glblPersonsBooked);
    console.log('Global nights number ==> ' + Variables.glblNightsBooked);
    //DBG//setTimeout(()=>{alert('Booked Persons : '+Variables.glblPersonsBooked)},7000);

    let betterPricing = '';
    /*
if ((glblNightsBooked < 7) && (glblNightsBooked > 5)) {
betterPricing = "\r"+(1*Variables.VillaVals.weekly_price)+ "€ for 1 weeks "
}
if ((glblNightsBooked < 14) && (glblNightsBooked > 10)) {
betterPricing = "\r"+(2*Variables.VillaVals.weekly_price)+ "€ for 2 weeks "
}
if ((glblNightsBooked < 21) && (glblNightsBooked > 17)) {
betterPricing = "\r"+(3*Variables.VillaVals.weekly_price)+ "€ for 3 weeks "
}
if ((glblNightsBooked < 30) && (glblNightsBooked >28 )) {
betterPricing = "\r"+(1*Variables.VillaVals.monthly_price)+ "€ for 1 month "
}
*/

    betterPricing = '';
    //setEstimatedPrice ( ""+(Variables.glblNightsBooked *  Variables.VillaVals.nightly_price) +" €  for "+glblNightsBooked+" nights booking.");
    let resaPrice =
      Variables.glblNightsBooked * Variables.VillaVals.nightly_price;
    //setEstimatedPrice ( ""+(Variables.glblNightsBooked *  Variables.VillaVals.nightly_price) +
    //                    " €  for "+Variables.glblNightsBooked+" nights booking."+betterPricing);

    let reducFactor = 1.0;
    if (Variables.glblPersonsBooked < 6 && Variables.glblNightsBooked > 6) {
      reducFactor = 0.99; // 1% less
    }

    if (Variables.glblPersonsBooked < 6 && Variables.glblNightsBooked > 9) {
      reducFactor = 0.98; // 2% less
    }

    if (Variables.glblPersonsBooked < 6 && Variables.glblNightsBooked > 14) {
      reducFactor = 0.975; // 2.5% less
    }
    if (Variables.glblPersonsBooked < 6 && Variables.glblNightsBooked > 19) {
      reducFactor = 0.97; // 3% less
    }
    let roundFactor = Variables.glblNightsBooked > 0 ? 0.5 : 0.0;
    let bestPrice = Math.round(reducFactor * resaPrice + roundFactor);
    if (resaPrice < bestPrice) bestPrice = resaPrice;
    setDuePrice(bestPrice); //
    console.log('resaPrice ==> ' + resaPrice);
    console.log('duePrice ==> ' + bestPrice);
    betterPricing =
      '\r\rYour Final Price\r' + bestPrice + ' € After reductions factors';
    setEstimatedPrice(
      '' +
        resaPrice +
        ' €  for ' +
        Variables.glblNightsBooked +
        ' nights & ' +
        Variables.glblPersonsBooked +
        ' persons.' +
        betterPricing
    );
  };

  const infoDownPayment = () => {
    let downPayment =
      'A 650€ down payment need to be paid to confirm your booking and\t' +
      ' the rest will have to be paid 30 days before your arrival.' +
      ' All late payments could compromise your reservation.\r\r' +
      ' For all enquiries please contact us by email on :\r\r\t\t\t\t info\b@\bvillaviews.fr';

    alert(downPayment);
  };

  const switchNavIfLoggedIn = Variables => {
    let _authToken = Variables.AuthToken;
    props.navigation.navigate('CompleteAccountFormScreen');
    /*
if (_authToken.length > 100 ) {
//already has the authToken prepared, after an earlier successful login
props.navigation.navigate("PaymentCardInfoScreen");

} else props.navigation.navigate("CompleteAccountFormScreen");//login to get new authToken
*/
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      assignGlblVars(Variables, setGlobalVariableValue);
      StatusBar.setBarStyle('light-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['myLightBlue'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.IMG0001}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', marginTop: 45, width: '100%' },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('BookingDatesScreen', {
                    startOfBooking: Constants['glblStartOfBooking'],
                    endOfBooking: Constants['glblEndOfBooking'],
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              hitSlop={3}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={theme.colors['Strong_Inverse']}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors['Strong_Inverse'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  marginTop: 11,
                },
                dimensions.width
              )}
            >
              {'Booking Estimates'}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setVisuMenu(Constants['glblTrue']);
                } catch (err) {
                  console.error(err);
                }
              }}
              hitSlop={3}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={theme.colors['Strong_Inverse']}
                  name={'Ionicons/menu-outline'}
                />
              </View>
            </Touchable>
          </View>
        </View>
        {/* View resumed fields */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              height: '100%',
              marginTop: 45,
              opacity: 1,
              width: '98%',
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                borderBottomWidth: 1,
                borderColor: theme.colors['Strong'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                height: 66,
                marginTop: 20,
                width: '90%',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                { color: theme.colors.strong, marginLeft: 5, marginTop: 5 },
                dimensions.width
              )}
            >
              {'Booking arrival date : '}
            </Text>
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              webShowOutline={true}
              defaultValue={arrivalDate}
              editable={false}
              placeholder={arrivalDate.toString()}
              placeholderTextColor={theme.colors['Strong']}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong_Inverse'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Strong'],
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderStyle: 'dotted',
                  borderTopWidth: 1,
                  color: theme.colors['Darker_Pink'],
                  fontFamily: 'Poppins_400Regular',
                  height: 37,
                  marginLeft: '3%',
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                  textAlign: 'center',
                  width: '94%',
                },
                dimensions.width
              )}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                borderBottomWidth: 1,
                borderColor: theme.colors['Strong'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                height: 66,
                marginTop: 20,
                width: '90%',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                { color: theme.colors.strong, marginLeft: 5, marginTop: 5 },
                dimensions.width
              )}
            >
              {'Booking departure date : '}
            </Text>
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              webShowOutline={true}
              defaultValue={departureDate}
              editable={false}
              placeholder={departureDate.toString()}
              placeholderTextColor={theme.colors['Strong']}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong_Inverse'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Strong'],
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderStyle: 'dotted',
                  borderTopWidth: 1,
                  color: theme.colors['Darker_Pink'],
                  fontFamily: 'Poppins_400Regular',
                  height: 37,
                  marginLeft: '3%',
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                  textAlign: 'center',
                  width: '94%',
                },
                dimensions.width
              )}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                borderBottomWidth: 1,
                borderColor: theme.colors['Strong'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                height: 66,
                marginTop: 20,
                width: '90%',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                { color: theme.colors.strong, marginLeft: 5, marginTop: 5 },
                dimensions.width
              )}
            >
              {'Total number of adults & children : '}
            </Text>
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                try {
                  setTotalPersons(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter a value...'}
              webShowOutline={true}
              editable={false}
              placeholderTextColor={theme.colors['Strong']}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong_Inverse'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Strong'],
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderStyle: 'dotted',
                  borderTopWidth: 1,
                  color: theme.colors['Darker_Pink'],
                  fontFamily: 'Poppins_400Regular',
                  height: 37,
                  marginLeft: '3%',
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                  textAlign: 'center',
                  width: '94%',
                },
                dimensions.width
              )}
              value={totalPersons}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                borderBottomWidth: 1,
                borderColor: theme.colors['Strong'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                height: 66,
                marginTop: 20,
                width: '90%',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                { color: theme.colors.strong, marginLeft: 5, marginTop: 5 },
                dimensions.width
              )}
            >
              {'Total number of nights booked : '}
            </Text>
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                try {
                  setTotalNights(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter a value...'}
              webShowOutline={true}
              editable={false}
              placeholderTextColor={theme.colors['Strong']}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong_Inverse'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Strong'],
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderStyle: 'dotted',
                  borderTopWidth: 1,
                  color: theme.colors['Darker_Pink'],
                  fontFamily: 'Poppins_400Regular',
                  height: 37,
                  marginLeft: '3%',
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                  textAlign: 'center',
                  width: '94%',
                },
                dimensions.width
              )}
              value={totalNights}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                borderBottomWidth: 1,
                borderColor: theme.colors['Strong'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderStyle: 'dashed',
                borderTopWidth: 1,
                height: 159,
                marginTop: 20,
                width: '90%',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  marginLeft: 5,
                  marginTop: 5,
                  textAlign: 'center',
                  textDecorationColor: theme.colors['Darker_Pink'],
                  textDecorationLine: 'underline',
                  textDecorationStyle: 'dashed',
                },
                dimensions.width
              )}
            >
              {'Your estimated rental price'}
            </Text>
            {/* estimatedPrice */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newEstimatedPriceValue => {
                try {
                  setEstimatedPrice(newEstimatedPriceValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter a value...'}
              webShowOutline={true}
              editable={false}
              multiline={true}
              numberOfLines={4}
              placeholderTextColor={theme.colors['Lighter_Pink']}
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Divider'],
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_600SemiBold',
                  marginLeft: '3%',
                  minHeight: 94,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                  textAlign: 'center',
                  width: '94%',
                },
                dimensions.width
              )}
              value={estimatedPrice}
            />
            {/* Button DownPayment */}
            <Button
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'glblDuePrice',
                    value: duePrice,
                  });
                  switchNavIfLoggedIn(Variables);
                } catch (err) {
                  console.error(err);
                }
              }}
              icon={'FontAwesome/shopping-basket'}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong'],
                  borderBottomWidth: 2,
                  borderColor: theme.colors['Strong_Inverse'],
                  borderLeftWidth: 1,
                  borderRadius: 21,
                  borderRightWidth: 2,
                  borderTopWidth: 1,
                  color: theme.colors['Strong_Inverse'],
                  fontFamily: 'Poppins_700Bold',
                  marginLeft: '35%',
                  marginTop: 15,
                  textAlign: 'center',
                  width: '55%',
                },
                dimensions.width
              )}
              title={' Down Payment'}
            />
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: '85%' },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    infoDownPayment();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Icon
                  size={24}
                  color={theme.colors['Strong']}
                  name={'Entypo/info-with-circle'}
                  style={StyleSheet.applyWidth(
                    {
                      marginLeft: 9,
                      marginRight: 15,
                      marginTop: -35,
                      width: '100%',
                      zIndex: 99,
                    },
                    dimensions.width
                  )}
                />
              </Touchable>
            </View>
          </View>
        </View>
        <View
          style={StyleSheet.applyWidth(
            { height: 45, width: '100%' },
            dimensions.width
          )}
        />
      </ImageBackground>
      {/* Modal Menu */}
      <>
        {!VisuMenu ? null : (
          <Modal
            animationType={'slide'}
            presentationStyle={'fullScreen'}
            transparent={true}
            visible={VisuMenu}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  backgroundColor: theme.colors['TripItBlue'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Strong'],
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  height: '84%',
                  marginBottom: 75,
                  marginTop: 30,
                  opacity: 0.95,
                  paddingBottom: 15,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 15,
                  width: '96%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'flex-end',
                    marginBottom: 75,
                    marginRight: 5,
                    marginTop: 5,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setVisuMenu(Constants['glblFalse']);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  hitSlop={3}
                >
                  <Icon size={24} name={'AntDesign/closecircleo'} />
                </Touchable>
              </View>
              {/* Button VillaViews */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('AllViewsScreen');
                    setVisuMenu(Constants['glblFalse']);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Strong_Inverse'],
                    borderLeftWidth: 2,
                    borderRadius: 8,
                    borderRightWidth: 2,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_700Bold',
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Villa Views'}
              />
              <Spacer left={8} right={8} bottom={18} top={18} />
              {/* Button VillaBookingDetails */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('VillaDetailsAndBookingScreen');
                    setVisuMenu(Constants['glblFalse']);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Strong_Inverse'],
                    borderLeftWidth: 2,
                    borderRadius: 8,
                    borderRightWidth: 2,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_700Bold',
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Villa Booking Details'}
              />
              <Spacer left={8} right={8} bottom={18} top={18} />
              {/* Button BookingCalendar */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('ResaCalendarScreen');
                    setVisuMenu(Constants['glblFalse']);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Strong_Inverse'],
                    borderLeftWidth: 2,
                    borderRadius: 8,
                    borderRightWidth: 2,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_700Bold',
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Booking Calendar'}
              />
              <Spacer left={8} right={8} bottom={18} top={18} />
              {/* Button Your Profile */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('AuthStack');
                    setVisuMenu(Constants['glblFalse']);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Strong_Inverse'],
                    borderLeftWidth: 2,
                    borderRadius: 8,
                    borderRightWidth: 2,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_700Bold',
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Your profile'}
              />
              <Spacer left={8} right={8} bottom={18} top={18} />
              {/* Button Disconnexion */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('AuthStack');
                    setVisuMenu(Constants['glblFalse']);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Strong_Inverse'],
                    borderLeftWidth: 2,
                    borderRadius: 8,
                    borderRightWidth: 2,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_700Bold',
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Sign off'}
              />
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(BookingEstimatesScreen);
