import React from 'react';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  KeyboardAvoidingView,
  NumberInput,
  Pressable,
  ScreenContainer,
  Shadow,
  Spacer,
  TextField,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, StatusBar, Text, View } from 'react-native';

const BookingDatesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [arrivalDate, setArrivalDate] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [datePickerValue2, setDatePickerValue2] = React.useState(new Date());
  const [departureDate, setDepartureDate] = React.useState('');
  const [dontProceed, setDontProceed] = React.useState(true);
  const [nightsStayed, setNightsStayed] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [numberInputValue2, setNumberInputValue2] = React.useState('');
  const [numberOfAdults, setNumberOfAdults] = React.useState(0);
  const [numberOfChildren, setNumberOfChildren] = React.useState(0);
  const [styledTextFieldValue, setStyledTextFieldValue] = React.useState('');
  const [styledTextFieldValue2, setStyledTextFieldValue2] = React.useState('');
  const [styledTextFieldValue3, setStyledTextFieldValue3] = React.useState('');
  const [styledTextFieldValue4, setStyledTextFieldValue4] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const AssignBookingDatesIfAny = (Variables, setGlobalVariableValue) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    const getDateFromEuroDisp = strDate => {
      //received in dd-mm-yyyy  format returned in yyyy-mm-dd
      let elms = strDate.split('-');
      const d = elms[0];
      const month = elms[1];
      const yr = elms[2];
      return `${yr}-${month}-${d}`;
    };

    function dateToYMD(date) {
      var d = date.getDate();
      var m = date.getMonth() + 1; //Month from 0 to 11
      var y = date.getFullYear();
      return (
        '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
      );
    }

    const consIntermedDatesStrs = (frmDte, tilDte) => {
      //costruct all intermed dates strs after frmDte upto tildate (excluded) : "yyyy-mm-dd,yyyy-mm-dd,..."
      let lstDtes = [];
      let day = new Date(frmDte);
      let nextDay = new Date(day);
      nextDay.setDate(day.getDate() + 1);
      let nxtDte = dateToYMD(nextDay);
      console.log('nextDay => ' + nxtDte);

      if (nxtDte === tilDte) return ''; //no intermed dates
      else {
        while (nxtDte < tilDte) {
          lstDtes[lstDtes.length] = nxtDte;
          nextDay.setDate(nextDay.getDate() + 1); //nxtDte += 1;
          nxtDte = dateToYMD(nextDay);
        }
      }
      if (lstDtes.length > 0) {
        return lstDtes.join(',');
      } else return '';
    };

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    setTextAreaValue(
      'You can book for up to ' +
        Variables.VillaVals.maxGuests +
        ' persons, adults and kids all together'
    );
    if (Variables.glblNightsBooked > 0) {
      setNightsStayed(
        'Booking for ' + Variables.glblNightsBooked + ' nights stay'
      );
    } else setNightsStayed('');

    //if ( undefined !== props.route.params.startOfBooking) {
    //if ( null !== Variables.glblStartOfBooking) {
    if (Variables.BackFromResaCalendar == true) {
      ////if (props.route.params.startOfBooking !== "") {
      if (Variables.glblStartOfBooking !== '') {
        //alert('Booking dates were already entered ==> '+props.route.params.startOfBooking);
        ////let _startDate = new Date(getDateFromEuroDisp(props.route.params.startOfBooking))
        let _startDate = new Date(
          getDateFromEuroDisp(Variables.glblStartOfBooking)
        );
        setArrivalDate(Variables.glblStartOfBooking);

        //alert('StartOfBooking ==> ' +glblStartOfBooking);
        //setStyledTextFieldValue2(glblStartOfBooking);
        //setDatePickerValue(_startDate);
      }
      ////if (props.route.params.endOfBooking !== "") {
      if (Variables.glblEndOfBooking !== '') {
        //alert('Booking dates were already entered ==> '+props.route.params.startOfBooking);
        ////let _endDate = new Date(getDateFromEuroDisp(props.route.params.endOfBooking))
        let _endDate = new Date(
          getDateFromEuroDisp(Variables.glblEndOfBooking)
        );
        setDepartureDate(Variables.glblEndOfBooking);
        //alert('EndOfBooking ==> ' +_endDate);
        //setStyledTextFieldValue3(glblEndOfBooking);
        //setDatePickerValue2(_endDate);
      }
    }
    //else {// prep NonDispoDates from Variables.apiResaBokdData
    let _allMrkdDtes = '';
    let _allBkdDtes = '';
    let MrkdDtesArr = []; // for all dbase already marked dates
    let BkdDtesArr = []; // for locally booked dates but not confirmed yet

    console.log('apiResaBokdData ==BkdDts==> ', Variables.apiResaBokdData);
    let apiBkdDts = Variables.apiResaBokdData;

    let MrkdDtes = {};
    if (apiBkdDts.length > 0) {
      let edgClr = '#0E86D4'; //theme.color.TripItBlue;
      let midClr = '#66B7FA'; //theme.color.myLightBlue;
      const slctdFrmObj = {
        startingDay: true,
        selected: true,
        marked: true,
        selectedColor: edgClr,
      };
      //{startingDay: true, color: 'green'},
      const slctdMidObj = { selected: true, color: midClr, marked: true };
      const slctdTilObj = {
        selected: true,
        endingDay: true,
        marked: true,
        selectedColor: edgClr,
      };
      for (var i = 0; i < apiBkdDts.length; i++) {
        let obj = apiBkdDts[i];
        var frmDte = new String(obj.fromDate); //alert("nonDispoFromDate ==>"+frmDte);
        var tilDte = obj.toDate;
        //var objArr = []; objArr[0] = frmDte; //`${frmDte}`

        let _nonDspoFrmObj =
          '"' + frmDte + '" : ' + JSON.stringify(slctdFrmObj) + '';
        MrkdDtesArr[MrkdDtesArr.length] = _nonDspoFrmObj;

        let _interMedDtes = consIntermedDatesStrs(frmDte, tilDte);
        //console.log( "_interMedDtes => " + _interMedDtes);
        let midDtesArr = _interMedDtes.split(',');
        for (var k = 0; k < midDtesArr.length; k++) {
          let mDte = midDtesArr[k];
          let _nonDspoMidObj =
            '"' + mDte + '" : ' + JSON.stringify(slctdMidObj) + '';
          MrkdDtesArr[MrkdDtesArr.length] = _nonDspoMidObj;
        }

        let _nonDspoTilObj =
          '"' + tilDte + '" : ' + JSON.stringify(slctdTilObj) + '';
        MrkdDtesArr[MrkdDtesArr.length] = _nonDspoTilObj; //alert("_nonDspoObj ==> "+_nonDspoObj);
      }
      ////_allMrkdDtes = "{"+MrkdDtesArr.join(",")+"}";
      ////console.log("All Marked Dates == "+_allMrkdDtes);
      ////MrkdDtes = JSON.parse(_allMrkdDtes);
      ////setGlobalVariableValue({key: "ResaNonDispoDates", value: MrkdDtes});
    }

    if (Variables.usrBookingDates !== '') {
      // allow redraw of usrBookingDates if any ???
      let usrBookingDates = Variables.usrBookingDates.split(',');

      let edgClr = 'orange';
      let midClr = 'orange';
      const slctdFrmObj = {
        startingDay: true,
        selected: true,
        marked: true,
        selectedColor: edgClr,
      };
      const slctdMidObj = {
        selected: true,
        marked: true,
        selectedColor: midClr,
      };
      //const slctdMidObj = {"selected":true,color: midClr, marked: true};
      const slctdTilObj = {
        selected: true,
        endingDay: true,
        marked: true,
        selectedColor: edgClr,
      };

      var frmDte = getDateFromEuroDisp(usrBookingDates[0]);
      var tilDte = getDateFromEuroDisp(usrBookingDates[1]);

      let _nonDspoFrmObj =
        '"' + frmDte + '" : ' + JSON.stringify(slctdFrmObj) + '';
      BkdDtesArr[BkdDtesArr.length] = _nonDspoFrmObj;

      let _interMedDtes = consIntermedDatesStrs(frmDte, tilDte);
      //console.log( "_interMedDtes => " + _interMedDtes);
      let midDtesArr = _interMedDtes.split(',');
      for (var k = 0; k < midDtesArr.length; k++) {
        let mDte = midDtesArr[k];
        let _nonDspoMidObj =
          '"' + mDte + '" : ' + JSON.stringify(slctdMidObj) + '';
        BkdDtesArr[BkdDtesArr.length] = _nonDspoMidObj;
      }

      let _nonDspoTilObj =
        '"' + tilDte + '" : ' + JSON.stringify(slctdTilObj) + '';
      BkdDtesArr[BkdDtesArr.length] = _nonDspoTilObj;
    }

    //if (MrkdDtesArr.length > 0) {

    if (BkdDtesArr.length > 0) {
      //alert("BkdDtesArr ==> "+BkdDtesArr.join("|"));
      for (var i = 0; i < BkdDtesArr.length; i++) {
        MrkdDtesArr[MrkdDtesArr.length] = BkdDtesArr[i];
      }
      //MrkdDtesArr.concat(BkdDtesArr);
    }
    //alert("MrkdDtesArr ==> "+MrkdDtesArr.join("|"));

    _allMrkdDtes = '{' + MrkdDtesArr.join(',') + '}';
    console.log('All Marked Dates == ' + _allMrkdDtes);
    MrkdDtes = JSON.parse(_allMrkdDtes);
    setGlobalVariableValue({ key: 'ResaNonDispoDates', value: MrkdDtes });
    //}

    console.log('Calc MrkdDtes ==> ' + JSON.stringify(MrkdDtes));
    //}
  };

  const ValidDataForBooking = (Variables, setGlobalVariableValue) => {
    //validate all booking vars before processing & proceeding to payment

    //helper function :
    function calindarDateFormat(strDate) {
      // from Disp to Cal format
      let elems = strDate.split('-');
      const d = elems[0];
      const month = elems[1];
      const yr = elems[2];
      return `${yr}-${month}-${d}`;
    }

    let maxPrsnsAllowed = Variables.VillaVals.maxGuests;
    let totPrsns = parseInt(numberOfAdults) + parseInt(numberOfChildren);
    if (
      arrivalDate !== '' &&
      departureDate !== '' &&
      totPrsns > 0 &&
      totPrsns <= maxPrsnsAllowed &&
      numberOfAdults > 0
    ) {
      //setGlobalVariableValue({key:'glblStartOfBooking', value : glblStartOfBooking});
      //setGlobalVariableValue({key:'glblEndOfBooking', value : glblEndOfBooking});

      let _totPrsns =
        parseInt(numberOfAdults, 10) + parseInt(numberOfChildren, 10);
      setGlobalVariableValue({ key: 'glblPersonsBooked', value: _totPrsns });

      let totNights = Variables.glblNightsBooked;
      setGlobalVariableValue({ key: 'glblNightsBooked', value: totNights });

      ////navigation.navigate('BookingEstimatesScreen');
      //navigation.navigate('ScreenStack', { screen: 'BookingEstimatesScreen', });
      //navigation.navigate('AuthStack', { screen: 'WelcomeScreen', });
    }

    if (arrivalDate === '' || departureDate === '') {
      alert(
        'Please choose the arival/departure dates from the Booking-Calendar.. '
      );
      return 0;
    }
    if (numberOfAdults <= 0 || totPrsns <= 0) {
      alert(
        'Have to have adults assigned, at least 1 adult; In addition a maximum of ' +
          maxPrsnsAllowed +
          ' , persons allowed, adults and kids all together. '
      );
      return 0;
    }

    if (totPrsns > maxPrsnsAllowed) {
      alert(
        'Maximum number of persons allowed is :' +
          maxPrsnsAllowed +
          ' ,  adults and kids all together.'
      );
      return 0;
    }

    // Check if dates are within season's start & end dates :
    if (Variables.VacSeasonStart !== '' && Variables.VacSeasonEnd !== '') {
      let seasStart = Variables.VacSeasonStart;
      let seasEnd = Variables.VacSeasonEnd;
      let seasonDtes = '{ ' + seasStart + ' - ' + seasEnd + ' }';

      let strtDte = calindarDateFormat(Variables.glblStartOfBooking);
      let endDte = calindarDateFormat(Variables.glblEndOfBooking);
      let bookingDtes = '[ ' + strtDte + ' - ' + endDte + ' ]';

      if (strtDte < seasStart || strtDte > seasEnd) {
        alert(
          ' Booking ' +
            bookingDtes +
            " start date has to be within season's start and end dates " +
            seasonDtes +
            ' try again.'
        );
        return 0;
      }
      if (endDte < seasStart || endDte > seasEnd) {
        alert(
          ' Booking ' +
            bookingDtes +
            " end date has to be within season's start and end dates " +
            seasonDtes +
            ' try again.'
        );
        return 0;
      }
    }

    props.navigation.navigate('BookingEstimatesScreen');
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      AssignBookingDatesIfAny(Variables, setGlobalVariableValue);
      StatusBar.setBarStyle('light-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasBottomSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['myLightBlue'] },
        dimensions.width
      )}
    >
      <ImageBackground resizeMode={'cover'} source={Images.IMG0001}>
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              marginBottom: 15,
              marginTop: 45,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={theme.colors['Strong_Inverse']}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors['Strong_Inverse'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  marginTop: 11,
                },
                dimensions.width
              )}
            >
              {'Booking Details'}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          ></View>
        </View>

        <KeyboardAvoidingView
          enabled={true}
          keyboardVerticalOffset={0}
          behavior={'height'}
          style={StyleSheet.applyWidth({ marginTop: 25 }, dimensions.width)}
        >
          <View
            collapsable={true}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'center',
                height: '98%',
                justifyContent: 'center',
                marginBottom: 8,
                marginLeft: 8,
                marginRight: 8,
                marginTop: -30,
                opacity: 0.74,
                position: 'relative',
                width: '98%',
              },
              dimensions.width
            )}
          >
            <Shadow
              offsetX={0}
              offsetY={0}
              showShadowCornerBottomEnd={true}
              showShadowCornerBottomStart={true}
              showShadowCornerTopEnd={true}
              showShadowCornerTopStart={true}
              showShadowSideBottom={true}
              showShadowSideEnd={true}
              showShadowSideStart={true}
              showShadowSideTop={true}
              paintInside={false}
              style={StyleSheet.applyWidth(
                {
                  borderRadius: 22,
                  height: 240,
                  marginTop: 5,
                  position: 'relative',
                  width: 340,
                },
                dimensions.width
              )}
            >
              {/* ViewCalendarBtn */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'flex-end',
                    left: 5,
                    marginRight: 15,
                    position: 'absolute',
                    right: 5,
                    top: 55,
                    width: '100%',
                    zIndex: 99,
                  },
                  dimensions.width
                )}
              >
                {/* Button Solid */}
                <Button
                  onPress={() => {
                    try {
                      navigation.navigate('ResaCalendarScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  icon={'FontAwesome/calendar'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Strong'],
                      borderBottomWidth: 2,
                      borderColor: theme.colors['Strong_Inverse'],
                      borderLeftWidth: 1,
                      borderRadius: 21,
                      borderRightWidth: 2,
                      borderTopWidth: 1,
                      color: theme.colors['Strong_Inverse'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 12,
                      marginBottom: 10,
                      marginRight: 15,
                      marginTop: -12,
                      textAlign: 'center',
                      width: '30%',
                    },
                    dimensions.width
                  )}
                  title={'Booking\nCalendar'}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: 15, marginTop: 11, width: '84%' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 5, width: '89%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'flex-start',
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 18,
                      },
                      dimensions.width
                    )}
                  >
                    {"You're arriving on :"}
                  </Text>

                  <Pressable
                    onPress={() => {
                      try {
                        navigation.navigate('ResaCalendarScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: 45, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* arrivalDate */}
                    <TextField
                      activeBorderColor={theme.colors.primary}
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newArrivalDateValue => {
                        try {
                          setArrivalDate(newArrivalDateValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      type={'solid'}
                      underlineColor={theme.colors.light}
                      webShowOutline={true}
                      editable={false}
                      label={'Arrival date : '}
                      placeholder={''}
                      placeholderTextColor={theme.colors['Strong']}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Strong_Inverse'],
                          color: theme.colors['Strong'],
                          fontFamily: 'Poppins_400Regular',
                          paddingLeft: 65,
                          width: '79%',
                        },
                        dimensions.width
                      )}
                      value={arrivalDate}
                    />
                  </Pressable>
                </View>
                <Spacer bottom={8} left={8} right={8} top={8} />
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'flex-end', width: '84%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'flex-start',
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 18,
                      },
                      dimensions.width
                    )}
                  >
                    {"& You're leaving on  : "}
                  </Text>
                  {/* departureDate */}
                  <TextField
                    activeBorderColor={theme.colors.primary}
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newDepartureDateValue => {
                      try {
                        setDepartureDate(newDepartureDateValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    type={'solid'}
                    underlineColor={theme.colors.light}
                    webShowOutline={true}
                    disabled={false}
                    editable={false}
                    label={'Departure Date : '}
                    placeholder={''}
                    placeholderTextColor={theme.colors['Strong']}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Strong_Inverse'],
                        color: theme.colors['Strong'],
                        fontFamily: 'Poppins_400Regular',
                        paddingLeft: 90,
                        width: '94%',
                      },
                      dimensions.width
                    )}
                    value={departureDate}
                  />
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignSelf: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Text 2 */}
                    <>
                      {!(nightsStayed !== '') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                alignSelf: 'center',
                                color: theme.colors['Strong'],
                                fontFamily: 'Poppins_600SemiBold',
                                marginTop: 15,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {nightsStayed}
                        </Text>
                      )}
                    </>
                  </View>
                </View>
              </View>
            </Shadow>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  borderColor: theme.colors['myStone'],
                  borderRadius: 22,
                  height: 400,
                  justifyContent: 'center',
                  marginBottom: 8,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: -13,
                  opacity: 1,
                  width: '98%',
                },
                dimensions.width
              )}
            >
              {/* Shadow 2 */}
              <Shadow
                offsetX={0}
                offsetY={0}
                showShadowCornerBottomEnd={true}
                showShadowCornerBottomStart={true}
                showShadowCornerTopEnd={true}
                showShadowCornerTopStart={true}
                showShadowSideBottom={true}
                showShadowSideEnd={true}
                showShadowSideStart={true}
                showShadowSideTop={true}
                paintInside={false}
                style={StyleSheet.applyWidth(
                  { borderRadius: 15, height: 70, width: 340 },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={newTextAreaValue => {
                    try {
                      setTextAreaValue(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  disabled={true}
                  editable={true}
                  numberOfLines={3}
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'center',
                      borderStyle: 'dotted',
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_600SemiBold',
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                      textAlign: 'center',
                      width: '90%',
                    },
                    dimensions.width
                  )}
                  value={textAreaValue}
                />
              </Shadow>

              <Shadow
                offsetX={0}
                offsetY={0}
                showShadowCornerBottomEnd={true}
                showShadowCornerBottomStart={true}
                showShadowCornerTopEnd={true}
                showShadowCornerTopStart={true}
                showShadowSideBottom={true}
                showShadowSideEnd={true}
                showShadowSideStart={true}
                showShadowSideTop={true}
                paintInside={false}
                stretch={false}
                style={StyleSheet.applyWidth(
                  {
                    borderRadius: 22,
                    height: 250,
                    marginTop: 5,
                    top: 15,
                    width: 340,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      marginLeft: 15,
                      marginTop: 5,
                      width: '90%',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong'],
                        fontFamily: 'Poppins_600SemiBold',
                      },
                      dimensions.width
                    )}
                  >
                    {'Number of Adults :'}
                  </Text>
                  <NumberInput
                    changeTextDelay={500}
                    onChangeText={newNumberInputValue => {
                      try {
                        setNumberOfAdults(newNumberInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a number...'}
                    webShowOutline={true}
                    clearButtonMode={'unless-editing'}
                    editable={true}
                    enablesReturnKeyAutomatically={true}
                    returnKeyType={'done'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          theme.colors['Communial_Icon_BG_Color'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors.divider,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: theme.colors['Strong'],
                        fontFamily: 'Poppins_400Regular',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    value={numberOfAdults}
                  />
                </View>
                <Spacer bottom={8} left={8} right={8} top={8} />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      justifyContent: 'flex-end',
                      marginLeft: 15,
                      marginTop: 5,
                      width: '90%',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_600SemiBold',
                      },
                      dimensions.width
                    )}
                  >
                    {'Children under 10 : '}
                  </Text>
                  {/* ChildrenUnder10 */}
                  <NumberInput
                    changeTextDelay={500}
                    onChangeText={newChildrenUnder10Value => {
                      try {
                        setNumberOfChildren(newChildrenUnder10Value);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Enter a number...'}
                    webShowOutline={true}
                    clearButtonMode={'unless-editing'}
                    editable={true}
                    enablesReturnKeyAutomatically={true}
                    returnKeyType={'done'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          theme.colors['Communial_Icon_BG_Color'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors.divider,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    value={numberOfChildren}
                  />
                </View>
                <Spacer bottom={8} left={8} right={8} top={8} />
                <Spacer bottom={8} left={8} right={8} top={8} />
                {/* ViewBookIt */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'flex-end', marginRight: 18 },
                    dimensions.width
                  )}
                >
                  {/* Button Solid */}
                  <Button
                    onPress={() => {
                      try {
                        ValidDataForBooking(Variables, setGlobalVariableValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    icon={'AntDesign/checkcircleo'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Strong'],
                        borderBottomWidth: 2,
                        borderColor: theme.colors['Strong_Inverse'],
                        borderLeftWidth: 1,
                        borderRadius: 21,
                        borderRightWidth: 2,
                        borderTopWidth: 1,
                        color: theme.colors['Strong_Inverse'],
                        fontFamily: 'Poppins_700Bold',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Book It'}
                  />
                </View>
              </Shadow>
            </View>
          </View>
        </KeyboardAvoidingView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BookingDatesScreen);
