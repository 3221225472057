import React from 'react';
import * as ExamplePropertiesApi from '../apis/ExamplePropertiesApi.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const HomeScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.background },
        dimensions.width
      )}
    >
      <Text
        accessible={true}
        style={StyleSheet.applyWidth(
          {
            color: theme.colors.strong,
            fontFamily: 'Poppins_600SemiBold',
            fontSize: 26,
            paddingBottom: 16,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 32,
          },
          dimensions.width
        )}
      >
        {'Popular Rentals'}
      </Text>

      <ExamplePropertiesApi.FetchListOfPropertiesGET limit={12} method={'GET'}>
        {({ loading, error, data, refetchListOfProperties }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <FlatList
              data={fetchData}
              horizontal={false}
              inverted={false}
              keyExtractor={(listData, index) =>
                listData?.id ?? listData?.uuid ?? index.toString()
              }
              keyboardShouldPersistTaps={'never'}
              listKey={'chjkgPWV'}
              nestedScrollEnabled={false}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listData = item;
                return (
                  <>
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors.surface,
                          borderBottomWidth: 1,
                          borderColor: theme.colors.divider,
                          borderLeftWidth: 1,
                          borderRadius: 8,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          overflow: 'hidden',
                        },
                        dimensions.width
                      )}
                    >
                      <Touchable>
                        <View
                          style={StyleSheet.applyWidth(
                            { height: 240 },
                            dimensions.width
                          )}
                        >
                          <ImageBackground
                            resizeMode={'cover'}
                            source={{ uri: `${listData?.image_url}` }}
                            style={StyleSheet.applyWidth(
                              {
                                borderRadius: theme.roundness,
                                height: '100%',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end', marginTop: 16 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor: theme.colors.primary,
                                    borderBottomLeftRadius: 8,
                                    borderTopLeftRadius: 8,
                                    flexDirection: 'row',
                                    paddingBottom: 4,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  ellipsizeMode={'tail'}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.surface,
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                  textBreakStrategy={'highQuality'}
                                >
                                  {'$'}
                                  {listData?.nightly_price}
                                </Text>

                                <Text
                                  accessible={true}
                                  ellipsizeMode={'tail'}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.surface,
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 10,
                                    },
                                    dimensions.width
                                  )}
                                  textBreakStrategy={'highQuality'}
                                >
                                  {'/night'}
                                </Text>
                              </View>
                            </View>
                          </ImageBackground>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 16,
                            },
                            dimensions.width
                          )}
                        >
                          <View>
                            <Text
                              accessible={true}
                              ellipsizeMode={'tail'}
                              numberOfLines={2}
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 18,
                                },
                                dimensions.width
                              )}
                              textBreakStrategy={'highQuality'}
                            >
                              {listData?.name}
                            </Text>
                            <Spacer left={8} right={8} bottom={4} top={4} />
                            <Text
                              accessible={true}
                              ellipsizeMode={'tail'}
                              numberOfLines={2}
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.medium, lineHeight: 24 },
                                dimensions.width
                              )}
                            >
                              {listData?.description}
                            </Text>
                            <Divider
                              color={theme.colors.divider}
                              style={StyleSheet.applyWidth(
                                { height: 1, marginBottom: 12, marginTop: 12 },
                                dimensions.width
                              )}
                            />
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  size={24}
                                  color={theme.colors.primary}
                                  name={'MaterialCommunityIcons/bed-king'}
                                />
                                <Spacer left={2} right={2} />
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.medium,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.beds}
                                  {' beds'}
                                </Text>
                              </View>
                              <Spacer bottom={8} left={8} right={8} top={8} />
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors.primary}
                                  name={'MaterialIcons/bathtub'}
                                  size={20}
                                />
                                <Spacer left={2} right={2} />
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.medium,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.bathrooms}
                                  {' baths'}
                                </Text>
                              </View>
                              <Spacer bottom={8} left={8} right={8} top={8} />
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  size={24}
                                  color={theme.colors.primary}
                                  name={'MaterialIcons/group'}
                                />
                                <Spacer left={2} right={2} />
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.medium,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.maxGuests}
                                  {' guests'}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </Touchable>
                    </View>
                    <Spacer left={8} right={8} bottom={12} top={12} />
                  </>
                );
              }}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  paddingBottom: 16,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
            />
          );
        }}
      </ExamplePropertiesApi.FetchListOfPropertiesGET>
    </ScreenContainer>
  );
};

export default withTheme(HomeScreen);
