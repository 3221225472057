import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Fire.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import * as GlobalVariables from './config/GlobalVariableContext';
import Breakpoints from './utils/Breakpoints';
import useIsOnline from './utils/useIsOnline';
import useWindowDimensions from './utils/useWindowDimensions';

import AllViewsScreen from './screens/AllViewsScreen';
import BookingDatesScreen from './screens/BookingDatesScreen';
import BookingEstimatesScreen from './screens/BookingEstimatesScreen';
import CompleteAccountFormScreen from './screens/CompleteAccountFormScreen';
import HomeScreen from './screens/HomeScreen';
import OneSectionCardsSwipingScreen from './screens/OneSectionCardsSwipingScreen';
import PaymentCardInfoScreen from './screens/PaymentCardInfoScreen';
import ProfileScreen from './screens/ProfileScreen';
import ResaCalendarScreen from './screens/ResaCalendarScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import SectionsMasonryListScreen from './screens/SectionsMasonryListScreen';
import SignInScreen from './screens/SignInScreen';
import SignUpScreen from './screens/SignUpScreen';
import SwiperSectorExplorerScreen from './screens/SwiperSectorExplorerScreen';
import VillaDetailsAndBookingScreen from './screens/VillaDetailsAndBookingScreen';
import VillaViewsAnimScreen from './screens/VillaViewsAnimScreen';
import VillaViewsScreen from './screens/VillaViewsScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function AuthStack() {
  const Constants = GlobalVariables.useValues();

  const isOnline = useIsOnline();

  return (
    <Stack.Navigator
      initialRouteName="VillaViewsAnimScreen"
      screenOptions={({ navigation }) => ({
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerMode: 'none',
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="VillaViewsScreen"
        component={VillaViewsScreen}
        options={({ navigation }) => ({
          title: 'VillaViews',
        })}
      />
      <Stack.Screen
        name="SignUpScreen"
        component={SignUpScreen}
        options={({ navigation }) => ({
          title: 'Sign Up',
        })}
      />
      <Stack.Screen
        name="SignInScreen"
        component={SignInScreen}
        options={({ navigation }) => ({
          title: 'Sign In',
        })}
      />
      <Stack.Screen
        name="VillaViewsAnimScreen"
        component={VillaViewsAnimScreen}
        options={({ navigation }) => ({
          title: 'VillaViewsAnim',
        })}
      />
    </Stack.Navigator>
  );
}

function MainTabs() {
  const tabBarIcons = {
    HomeScreen: 'Feather/home',
    ProfileScreen: 'Feather/user',
  };

  return (
    <Tab.Navigator
      screenOptions={({ navigation }) => ({
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
        tabBarActiveBackgroundColor: theme.colors['MyBlack'],
        tabBarInactiveBackgroundColor: theme.colors['MyBlack'],
        tabBarShowLabel: false,
        tabBarStyle: { borderTopColor: 'transparent' },
      })}
    >
      <Tab.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Feather/home"
              size={25}
              color={focused ? theme.colors['Golden'] : theme.colors.light}
            />
          ),
          tabBarLabel: 'Home',
          title: 'Home',
        })}
      />
      <Tab.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Feather/user"
              size={25}
              color={focused ? theme.colors['Golden'] : theme.colors.light}
            />
          ),
          tabBarLabel: 'Profile',
          title: 'Profile',
        })}
      />
    </Tab.Navigator>
  );
}

function ScreenStack() {
  /* Navigator has no children, add a child screen or navigator to have it rendered here */
}

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#F6F8FD',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="AuthStack"
        screenOptions={({ navigation }) => ({
          animationEnabled: false,
          cardOverlayEnabled: false,
          gestureEnabled: false,
          gestureVelocityImpact: 0.7,
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerMode: 'none',
          headerShown: false,
          tabBarShowLabel: true,
        })}
      >
        <Stack.Screen
          name="AllViewsScreen"
          component={AllViewsScreen}
          options={({ navigation }) => ({
            title: 'AllViews',
          })}
        />
        <Stack.Screen
          name="VillaDetailsAndBookingScreen"
          component={VillaDetailsAndBookingScreen}
          options={({ navigation }) => ({
            title: 'VillaDetailsAndBooking',
          })}
        />
        <Stack.Screen
          name="BookingDatesScreen"
          component={BookingDatesScreen}
          options={({ navigation }) => ({
            title: 'BookingDates',
          })}
        />
        <Stack.Screen
          name="ResaCalendarScreen"
          component={ResaCalendarScreen}
          options={({ navigation }) => ({
            title: 'ResaCalendar',
          })}
        />
        <Stack.Screen
          name="BookingEstimatesScreen"
          component={BookingEstimatesScreen}
          options={({ navigation }) => ({
            title: 'BookingEstimates',
          })}
        />
        <Stack.Screen
          name="PaymentCardInfoScreen"
          component={PaymentCardInfoScreen}
          options={({ navigation }) => ({
            title: 'PaymentCardInfo',
          })}
        />
        <Stack.Screen
          name="CompleteAccountFormScreen"
          component={CompleteAccountFormScreen}
          options={({ navigation }) => ({
            title: 'CompleteAccountForm',
          })}
        />
        <Stack.Screen
          name="ResetPasswordScreen"
          component={ResetPasswordScreen}
          options={({ navigation }) => ({
            title: 'ResetPassword',
          })}
        />
        <Stack.Screen
          name="SwiperSectorExplorerScreen"
          component={SwiperSectorExplorerScreen}
          options={({ navigation }) => ({
            title: 'SwiperSectorExplorer',
          })}
        />
        <Stack.Screen
          name="SectionsMasonryListScreen"
          component={SectionsMasonryListScreen}
          options={({ navigation }) => ({
            title: 'SectionsMasonryList',
          })}
        />
        <Stack.Screen
          name="OneSectionCardsSwipingScreen"
          component={OneSectionCardsSwipingScreen}
          options={({ navigation }) => ({
            animationEnabled: false,
            cardOverlayEnabled: false,
            gestureEnabled: false,
            title: 'OneSectionCardsSwiping',
          })}
        />
        <Stack.Screen name="AuthStack" component={AuthStack} />
        <Stack.Screen name="MainTabs" component={MainTabs} />
        <Stack.Screen name="ScreenStack" component={ScreenStack} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
