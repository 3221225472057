import React from 'react';
import * as CustomCode from '../CustomCode';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CalendarListComp from '../custom-files/CalendarListComp';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  TextField,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  Alert,
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';

const ResaCalendarScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [endOfBooking, setEndOfBooking] = React.useState('');
  const [seasonDates, setSeasonDates] = React.useState('');
  const [startOfBooking, setStartOfBooking] = React.useState('');
  const [styledTextFieldValue, setStyledTextFieldValue] = React.useState('');
  const [styledTextFieldValue2, setStyledTextFieldValue2] = React.useState('');
  const dispAllowedResaPeriode = Variables => {
    // show VacSeasonStart - VacSeasonEnd reservations Periode :

    const conv2EuropeDate = dte => {
      let dteElms = dte.split('-');
      let yr = dteElms[0];
      let mn = dteElms[1];
      let dd = dteElms[2];
      return `${dd}/${mn}/${yr}`;
    };
    let startDte = Variables.VacSeasonStart;
    let endDte = Variables.VacSeasonEnd;
    let seasDates = conv2EuropeDate(startDte) + ' - ' + conv2EuropeDate(endDte);
    setSeasonDates(seasDates);

    let usrBookingDates = Variables.usrBookingDates;
    //alert("Variables.usrBookingDates ==>"+Variables.usrBookingDates);
    if (usrBookingDates !== '') {
      let bkngDtesArr = usrBookingDates.split(',');
      setStartOfBooking(bkngDtesArr[0]);
      setEndOfBooking(bkngDtesArr[1]);
    }

    if (startDte !== '' && endDte !== '') {
      //setTimeout( () => {
      Alert.alert(
        'Possible reservation dates :',
        'Reservations Season \r starts on : ' +
          startDte +
          '  and end on : ' +
          endDte +
          '\r Please respect this periode while picking dates.'
      );
      //}, 9750);
    }
  };

  const ValidateBookingDates = (Variables, setGlobalVariableValue) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    function calindarDateFormat(strDate) {
      // from Disp to Cal format
      let elems = strDate.split('-');
      const d = elems[0];
      const month = elems[1];
      const yr = elems[2];
      return `${yr}-${month}-${d}`;
    }

    function countNightsNumber(strt, end) {
      let _strt = new Date(calindarDateFormat(strt));
      let _end = new Date(calindarDateFormat(end));

      const one_day = 1000 * 60 * 60 * 24;
      let result;
      result = Math.ceil((_end.getTime() - _strt.getTime()) / one_day);
      console.log('Nights slept on premises : ', result, ' nights');
      if (result < 0) {
        return 0;
      }
      return result;
    }

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    let errFound = false;
    let countOfNights = 0;
    let minNightsAllowed = parseInt(Variables.VillaVals.min_stay);

    //console.log("=========> 0 ")
    if (startOfBooking !== '' && endOfBooking !== '') {
      //console.log("=========> 1 ")
      countOfNights = countNightsNumber(startOfBooking, endOfBooking);
      //console.log("=========> 1.1  minStay ==> "+minNightsAllowed);
      if (countOfNights < minNightsAllowed) {
        //console.log("=========> 2 ")
        alert(
          'Minumum nights to book is : ' +
            minNightsAllowed +
            ' nights , and you only reserved : ' +
            countOfNights +
            ' nights , you need to extend your stay .'
        );
        errFound = true;
        return 0;
      }
      // Check if dates are within season's start & end dates :
      if (Variables.VacSeasonStart !== '' && Variables.VacSeasonEnd !== '') {
        let seasStart = Variables.VacSeasonStart;
        let seasEnd = Variables.VacSeasonEnd;
        let seasonDtes = '{ ' + seasStart + ' - ' + seasEnd + ' }';

        let strtDte = calindarDateFormat(startOfBooking); //Variables.glblStartOfBooking);
        let endDte = calindarDateFormat(endOfBooking); //Variables.glblEndOfBooking);
        let bookingDtes = '[ ' + strtDte + ' - ' + endDte + ' ]';

        if (strtDte < seasStart || strtDte > seasEnd) {
          alert(
            ' Booking ' +
              bookingDtes +
              " start date has to be within season's start and end dates " +
              seasonDtes +
              ' try again.'
          );

          errFound = true;
          return 0;
        }
        if (endDte < seasStart || endDte > seasEnd) {
          alert(
            ' Booking ' +
              bookingDtes +
              " end date has to be within season's start and end dates " +
              seasonDtes +
              ' try again.'
          );

          errFound = true;
          return 0;
        }
        //alert("Booked dates are valide.")
      }

      //setGlobalVariableValue({"key":"BackFromResaCalendar","value":true});
      //props.navigation.navigate("BookingDatesScreen");

      if (errFound == false) {
        let usrBookingDates = startOfBooking + ',' + endOfBooking;
        //alert("After BkngDtes Validation usrBookingDates ==> "+usrBookingDates);
        setGlobalVariableValue({
          key: 'usrBookingDates',
          value: usrBookingDates,
        }); //glbl
        setGlobalVariableValue({
          key: 'glblStartOfBooking',
          value: startOfBooking,
        });
        setGlobalVariableValue({
          key: 'glblEndOfBooking',
          value: endOfBooking,
        });
        setGlobalVariableValue({
          key: 'glblNightsBooked',
          value: countOfNights,
        });
        setGlobalVariableValue({ key: 'BackFromResaCalendar', value: true });
        props.navigation.navigate('BookingDatesScreen');
      }
    } else {
      alert('Invalide booking start or end dates, try again...');
      errFound = true;
      return 0;
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      dispAllowedResaPeriode(Variables);
      StatusBar.setBarStyle('light-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['myLightBlue'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        backgroundColor={theme.colors['Strong']}
        source={Images.IMG0001}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', marginTop: 33, width: '100%' },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={theme.colors['Strong_Inverse']}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors['Strong_Inverse'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  marginTop: 11,
                },
                dimensions.width
              )}
            >
              {'Booking Calendar'}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          ></View>
        </View>
        {/* ScrollView CalResaMonths */}
        <ScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          style={StyleSheet.applyWidth({ width: '98%' }, dimensions.width)}
          contentContainerStyle={StyleSheet.applyWidth(
            { marginLeft: '1%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'center',
                justifyContent: 'center',
                zIndex: 999,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'Poppins_600SemiBold',
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {
                'Tap on start & end uncolored dates\nSwipe left or right to change months\nSeason Dates :   '
              }
              {seasonDates}
            </Text>
          </View>
          <Utils.CustomCodeErrorBoundary>
            <CalendarListComp.DisposCalendarList
              setStartOfBooking={setStartOfBooking}
              setEndOfBooking={setEndOfBooking}
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
          {/* Start of booking */}
          <TextField
            activeBorderColor={theme.colors.primary}
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newStartOfBookingValue => {
              try {
                setStartOfBooking(newStartOfBookingValue);
              } catch (err) {
                console.error(err);
              }
            }}
            type={'solid'}
            underlineColor={theme.colors.light}
            webShowOutline={true}
            editable={false}
            label={'Booking start date : '}
            placeholder={''}
            placeholderTextColor={theme.colors['Strong']}
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                backgroundColor: theme.colors['Strong_Inverse'],
                fontFamily: 'Poppins_400Regular',
                marginTop: 10,
                paddingLeft: '30%',
              },
              dimensions.width
            )}
            value={startOfBooking}
          />
          {/* End of booking */}
          <TextField
            activeBorderColor={theme.colors.primary}
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newEndOfBookingValue => {
              try {
                setEndOfBooking(newEndOfBookingValue);
              } catch (err) {
                console.error(err);
              }
            }}
            type={'solid'}
            underlineColor={theme.colors.light}
            webShowOutline={true}
            editable={false}
            label={'Booking end date : '}
            placeholder={''}
            placeholderTextColor={theme.colors['Strong']}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Strong_Inverse'],
                fontFamily: 'Poppins_400Regular',
                marginTop: 10,
                paddingLeft: '30%',
              },
              dimensions.width
            )}
            value={endOfBooking}
          />
          {/* Button Accept Dates */}
          <Button
            onPress={() => {
              try {
                ValidateBookingDates(Variables, setGlobalVariableValue);
                console.log();
              } catch (err) {
                console.error(err);
              }
            }}
            icon={'MaterialCommunityIcons/calendar-today'}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Strong'],
                borderBottomWidth: 2,
                borderColor: theme.colors['Strong_Inverse'],
                borderLeftWidth: 1,
                borderRadius: 21,
                borderRightWidth: 2,
                borderTopWidth: 1,
                color: theme.colors['Strong_Inverse'],
                fontFamily: 'Poppins_700Bold',
                marginBottom: 45,
                marginLeft: '50%',
                marginRight: 15,
                marginTop: 25,
                textAlign: 'center',
                width: '45%',
              },
              dimensions.width
            )}
            title={'Approve dates'}
          />
        </ScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(ResaCalendarScreen);
