import React, { useRef } from 'react';
import { Swiper, SwiperItem, Touchable, Icon } from '@draftbit/ui';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Image,
  Platform,
  Dimensions,
  Modal,
  TouchableOpacity,
} from 'react-native';
import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';
import Images from '../config/Images';
import * as ImgDico from '../custom-files/ImageDico.js';

import * as Sharing from 'expo-sharing';
import * as ExpoFileSys from 'expo-file-system';

async function trySharingPhoto(foto) {
  console.log('the Foto ===> ' + foto);
  const fileUri = ExpoFileSys.cacheDirectory + 'tmp.jpg';
  var imgUrl = Images.IMG2084; //'../config/Images/IMG2084.PNG';//"https://i.imgur.com/B8rw1pu.jpeg";//'https://imgur.com/FDbumx3';
  imgUrl =
    'https://apps-draftbit-com.s3.amazonaws.com/apps/0hbNFf4L/assets/cOA6iTRQq3i6GIM9_opas';
  var canShare = false;
  Sharing.isAvailableAsync().then(available => {
    if (available) {
      canShare = true;
      console.log('Sharing is available');
      ///////////////////////////////////////////////
      console.log('Now ==> Sharing');
      const options = {
        mimeType: 'image/jpeg',
        dialogTitle: 'Share the image',
        UTI: 'image/jpeg',
      };

      ExpoFileSys.downloadAsync(imgUrl, fileUri)
        .then(({ uri }) => {
          console.log(`Downloaded image to ${uri}`);
        })
        .catch(err => {
          console.log('Error downloading image');
          console.log(JSON.stringify(err));
        });

      // Sharing only allows one to share a file.
      Sharing.shareAsync(fileUri, options)
        .then(data => {
          console.log('Shared');
        })
        .catch(err => {
          console.log('Error sharing image');
          console.log(JSON.stringify(err));
        });
      ////////////////////////////////////////////////
    } else {
      canShare = false;
      console.log('Sharing is NOT available');
    }
  });
}

var imgId = 1;

export const Swipe = props => {
  const { theme } = props;
  const { myData } = props;
  var { showModal } = props;
  const { setShowModal } = props;
  var { img2Zoom } = props;
  const { setImg2Zoom } = props;
  var { listChanged } = props;
  const { setListChanged } = props;

  const mdlRef = useRef();
  const imgRef = useRef();
  if (listChanged) {
    imgId = 1; // init of file glbl var when we arrive after dataSet section change
    setListChanged(false);
  }

  const { height, width } = Dimensions.get('window');
  let itmMaxWd = width * 0.88; //
  let itmMaxHt = 375;
  let swiperHt = 467;
  let minWd = width - 29;
  let imgWd = 320;
  let imgHt = 345;
  let btnsMrgLft = '60%';
  //alert("Width==> "+width+"  & Height ==> "+ height);
  if (width >= 800) {
    imgWd = 600;
    imgHt = 640;
    itmMaxWd = width * 0.78;
    itmMaxHt = height - 300;
    swiperHt = 890;
    btnsMrgLft = '70%';
    if (width > height) {
      if (height <= 820) {
        //ipad landscape mode
        swiperHt = 520;
      } else {
        //big tablettes or PC wide screen
        swiperHt = 390;
      }
    }
  }

  let mrgTp = -5;
  if (Platform.OS === 'ios') mrgTp = -120;
  if (Platform.OS === 'android') mrgTp = -140;

  const currImage = imgStr => {
    //  cardData[itemId-1].imgUri;
    //return (dico[imgStr]); // { , }
    const theImg = ImgDico.Dico[imgStr];
    if (typeof theImg === 'string') {
      console.log('imgUri =CloudImg=> ' + theImg);
      return { uri: theImg };
    } else return theImg; //const theImg =  ImgDico.Dico[imgStr];
  };

  const getImgById = id => {
    // id > 0 of course
    if (id <= 0) id = 1;
    let imgStr = myData[id - 1].img; //return (dico[myData[ id - 1 ].img])
    const theImg = ImgDico.Dico[imgStr];
    if (typeof theImg === 'string') {
      console.log('imgUri =CloudImgId=> ' + theImg);
      return { uri: theImg };
    } else return theImg; //ImgDico.Dico[imgStr]);
  };

  const orientationChanged = str => {
    alert('Orientation changed ');
  };

  return (
    <>
      <Swiper
        style={{ flex: 1, marginTop: -35, maxWidth: '99%' }}
        data={myData}
        keyExtractor={swiperData =>
          swiperData?.id || swiperData?.uuid || JSON.stringify(swiperData)
        }
        renderItem={({ item }) => {
          const swiperData = item;
          {
            /* 
                  //,maxHeight:409 //  // //<View style={{marginTop:-175}} ></View>
                  // style={{width:'100%',height:null,minHeight:417,alignSelf:'stretch',minWidth:maxWd,marginTop:4}}
                  // <SwiperItem style={{height:375,width:'100%',marginTop:-30,marginBottom:29}}>
                */
          }
          return (
            <SwiperItem
              style={{
                height: itmMaxHt,
                width: '100%',
                marginTop: -30,
                marginBottom: 29,
                padding: 24,
              }}
            >
              <View
                style={{
                  flex: 1,
                  width: '100%',
                  minWidth: itmMaxWd,
                  height: itmMaxHt,
                  alignSelf: 'center',
                }}
              >
                {/* 
                      <ReactNativeZoomableView
                            maxZoom={30}
                            minZoom={1}
                            contentWidth={375}
                            contentHeight={350}
                          >
                          //maxHeight:320,
                      */}
                <Image
                  style={{
                    flex: 1,
                    zIndex: 29,
                    width: '99%',
                    width: imgWd,
                    height: imgHt,
                    aspectRatio: 1,
                    marginTop: 44,
                    alignSelf: 'center',
                  }}
                  ref={imgRef}
                  source={currImage(item.img)}
                  resizeMode={'contain'}
                />
                {/*</ReactNativeZoomableView> */}

                <View
                  style={{
                    width: '100%',
                    height: 45,
                    zIndex: 44,
                    marginTop: -9,
                    marginLeft: btnsMrgLft,
                    marginRight: 9,
                  }}
                >
                  <View
                    style={{
                      width: '90%',
                      marginTop: 2,
                      marginBottom: 2,
                      alignContent: 'flex-end',
                      alignSelf: 'flex-end',
                      marginRight: 5,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        marginRight: 5,
                        width: 49,
                      }}
                    >
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await trySharingPhoto(currImage(item.img));
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <Icon
                          size={24}
                          name={'Feather/share'}
                          color={theme.colors.Strong_Inverse}
                        />
                      </Touchable>
                      <View style={{ marginLeft: 10, marginRight: 5 }} />
                      <Touchable
                        onPress={() => {
                          try {
                            //showZoomView = true ;
                            mdlRef.visible = true;
                            imgId = item.id;
                            setImg2Zoom(myData[item.id - 1].img);
                            console.log(
                              'zooming In : ' +
                                img2Zoom +
                                ' =id=> ' +
                                imgId +
                                ' => imgById => ' +
                                myData[imgId - 1].img
                            );
                            setShowModal(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Icon
                          size={24}
                          color={theme.colors.Strong_Inverse}
                          name={'Feather/zoom-in'}
                        />
                      </Touchable>
                    </View>
                  </View>
                </View>

                <View style={{ marginTop: -25, padding: 5 }}>
                  <Text
                    style={{
                      fontSize: 16,
                      width: '94%',
                      color: theme.colors.Strong,
                      marginLeft: 3,
                    }}
                  >
                    {swiperData?.title}
                  </Text>
                  <View
                    style={{
                      width: '99%',
                      marginTop: 0,
                      backgroundColor: theme.colors.TripItBlue,
                      border: '1px dotted white',
                      borderRadius: 6,
                      alignSelf: 'center',
                    }}
                  >
                    <TextInput
                      style={{
                        width: '96%',
                        paddingTop: 1,
                        alignSelf: 'center',
                        color: theme.colors.Strong,
                      }}
                      textAlignVertical={'top'}
                      editable={false}
                      multiline={true}
                      numberOfLines={4}
                    >
                      {swiperData?.descr}
                    </TextInput>
                  </View>
                </View>
              </View>
            </SwiperItem>
          );
        }}
        style={{
          justifyContent: 'center',
          height: swiperHt,
          width: '94%',
          marginTop: -47,
        }}
        dotColor={theme.colors.light}
        dotActiveColor={theme.colors.Golden} //Studily_Primary //Primary
        dotsTouchable={true}
        loop={false}
      />

      <Modal
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 109,
          height: '100%',
          width: '100%',
          backgroundColor: theme.colors.myLightBlue,
        }}
        visible={showModal}
        animationType={'slide'}
        transparent={false}
        //ref = {mdlRef}
        supportedOrientations={['portrait', 'landscape']}
        onOrientationChange={() => {
          console.log('onOrientationChange fired ...');
          //orientationChanged('');
        }}
        onRequestClose={() => {
          console.log(
            'Modal has been closed. = ' +
              imgId +
              ' => ' +
              dico[myData[imgId - 1].img]
          );
          setShowModal(false);
        }}
      >
        <View
          style={{
            top: 0,
            paddingTop: 1,
            alignSelf: 'center',
            borderRadius: 7,
            backgroundColor: theme.colors.TripItBlue,
            width: '99%',
            height: '99%',
          }}
        >
          <View
            style={{
              alignSelf: 'flex-end',
              top: 45,
              marginRight: 9,
              zIndex: 199,
            }}
          >
            <Touchable
              onPress={() => {
                try {
                  setShowModal(false);
                  mdlRef.visible = false;
                  console.log(
                    'Should close zoom view =id=> ' +
                      imgId +
                      ' =img=> ' +
                      img2Zoom
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Icon
                size={24}
                name={'AntDesign/closecircle'}
                color={theme.colors.Strong_Inverse}
              />
            </Touchable>
          </View>

          <Text
            style={{
              left: 9,
              position: 'absolute',
              top: 45,
              zIndex: 199,
              fontSize: 14,
              alignSelf: 'flex-start',
              color: theme.colors.Strong_Inverse,
            }}
          >
            {'Double tap or\n pinch the photo\n to zoom it : '} {/*imgId*/}
            {/* {' => '}{dico[myData[imgId-1].img]} */}
          </Text>

          <ReactNativeZoomableView
            maxZoom={9}
            minZoom={0.7}
            initialZoom={0.7}
            contentWidth={370}
            contentHeight={370}
            bindToBorders={true}
            doubleTapZoomToCenter={true}
            //onZoomAfter={this.logOutZoomState}
          >
            <View
              style={{
                width: '100%',
                height: 496,
                marginTop: -45,
                zIndex: 169,
              }}
            >
              <Image
                style={{ flex: 1, width: '100%', height: null }}
                source={getImgById(imgId)}
                resizeMode={'contain'}
              />
            </View>
          </ReactNativeZoomableView>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  Fetch431eb058: { minHeight: 40 },
  Imaged06b86ef: { height: 100, width: 100 },
  Swiper4f06a827: { height: 420, width: '100%' },
});
/*****************
  {!showZoomView ? null : (
            <Modal
              visible={showZoomView}
              animationType={'slide'}
              transparent={true}
            >
              <View style={styles(theme).View8bd214dc}>
                <View style={styles(theme).View04da1230}>
                  <Touchable
                    onPress={() => {
                      try {
                        setImg2Zoom('IMG2084');
                        setShowZoomView(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Icon
                      size={24}
                      name={'AntDesign/closecircle'}
                      color={theme.colors['Strong_Inverse']}
                    />
                  </Touchable>
                </View>

                <Text style={styles(theme).Text251d9f8e}>
                  {'Tap or pinch the photo to zoom it'}
                </Text>

                <View style={styles(theme).Viewfd918f7e}>
                  <Utils.CustomCodeErrorBoundary>
                    <ZoomableImage.Zooms
                      theme={props.theme}
                      navigation={props.navigation}
                      imgName={img2Zoom}
                    />
                  </Utils.CustomCodeErrorBoundary>
                </View>
              </View>
            </Modal>
          )}
 ******************/

/*
                   <View style={{display:{tglShow},zIndex:999 }}>
                      <ReactNativeZoomableView
                            maxZoom={30}
                            contentWidth={375}
                            contentHeight={350}

                          >
                        <Image style={{flex:1,width:'100%',height:355,aspectRatio:1.0,maxHeight:375,marginTop:0,alignSelf:'center'}}
                          source={dico[item.img]}
                          resizeMode={'contain'}
                        />  
                      </ReactNativeZoomableView>
                   </View>
                   */

/*
    let mydata = [
              {"id":1,"title":"my title1","img":"./images/img1.png","descr":"Text 11"},
              {"id":2,"title":"my title2","img":"./images/img2.png","descr":"Text 21"},
              {"id":3,"title":"my title3","img":"./images/img3.png","descr":"Text 31"}
              ];
  * /
          //let imgDta = {id:1,src:require('..\/config\/Images\/IMG3427.jpg')}
          //let imgNme = {id:1,src:IMG3427}
          //listKey={'fdsTfnpJ'}
                //var myImg = imgNme.src;//'IMG3427';
                //let IMG =  `${Images}.${myImg}`;//  //Images.IMG3427 // {swiperData?.img} //Images.IMG3427; //IMG3427; //IMG1028
                //const IMG = Images.IMG3427;          
                //let IMG =  eval(Images+"."+test);
                //console.log("test",IMG)
                //let IMG = {imgStr};
                //IMG = `require('../config/Images/${myImg}.jpg')`;
                //console.log("itemIMG ==> ",IMG);//("aaaaa",swiperData);
                //source={item.imgObj}
                //let test = JSON.stringify(Images+"."+item.img)
                //Communial_White
  */
