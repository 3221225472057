import React from 'react';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as VillaVuXanoApi from '../apis/VillaVuXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useIsOnline from '../utils/useIsOnline';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, Link, ScreenContainer, Spacer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, StatusBar, Text, View } from 'react-native';

const VillaViewsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [AppIsOnLine, setAppIsOnLine] = React.useState(true);
  const [DfltBkGrnd, setDfltBkGrnd] = React.useState(
    'https://res.cloudinary.com/dcmydoriy/image/upload/v1677592051/ViVi/vvFond_un0fbw.jpg'
  );
  const assignVillaVals = (Variables, setGlobalVariableValue) => {
    if (Variables.VillasData.length == 1) {
      var oneVilla = Variables.VillasData[0];

      setTimeout(() => {
        setGlobalVariableValue({ key: 'VillaVals', value: oneVilla });
        setGlobalVariableValue({ key: 'VillaId', value: oneVilla.id });

        console.log('===> juste set VillaVals from Xano data api source.');
        console.log('===Data-Villa-Name> ' + oneVilla.name);
        console.log('===Data-Villa-Id> ' + oneVilla.id);
        console.log('===Vals-Villa-Name> ' + Variables.VillaVals.name);
        console.log('===Glbl-Villa-Id> ' + Variables.VillaId);
      }, 0);
    }
  };

  const isOnlineCtrl = appOnline => {
    if (!appOnline) {
      alert('App needs internet connexion, please reconnect. ');
    } else console.log('Welcome into VillaViews');
  };

  const assignVacSeasonYear = (Variables, setGlobalVariableValue) => {
    // assign curren vacation season's year : yyyy
    let dte = new Date();
    let yr = dte.getFullYear();
    let yyyy = '' + yr;
    //alert(" Yyyy ==> "+yyyy);
    setGlobalVariableValue({ key: 'VacYear', value: yyyy });
    setTimeout(() => {
      console.log(' The Variables.VacYear ==> ' + Variables.VacYear);
    }, 0);

    return yyyy;
  };

  const extractVacSeasonInfo = (
    Variables,
    setGlobalVariableValue,
    vacSeasonRecs
  ) => {
    // get the VacSeasonID from the API call's returnd rec
    let vacSeasonRec = vacSeasonRecs[0];
    let id = vacSeasonRec.id;
    let villaId = vacSeasonRec.listings_id;
    console.log('Villa_Id ==> ', villaId);
    let seasStartDate = vacSeasonRec.startdate.split('T')[0];
    let seasEndDate = vacSeasonRec.enddate.split('T')[0];
    let hiSeasStart = vacSeasonRec.histartdate.split('T')[0];
    let hiSeasEnd = vacSeasonRec.hienddate.split('T')[0];
    //let villaImgUrl = vacSeasonRec.image_url;

    console.log(
      'Villa #' +
        villaId +
        ' Main season start - end ==> ' +
        seasStartDate +
        ' - ' +
        seasEndDate
    );
    console.log(
      'Villa #' +
        villaId +
        ' High season start - end ==> ' +
        hiSeasStart +
        ' - ' +
        hiSeasEnd
    );
    //console.log("Villa image_url ==> ",villaImgUrl);
    setGlobalVariableValue({ key: 'VacSeasonId', value: id });
    setGlobalVariableValue({ key: 'VacSeasonStart', value: seasStartDate });
    setGlobalVariableValue({ key: 'VacSeasonEnd', value: seasEndDate });
    setGlobalVariableValue({ key: 'VillaId', value: villaId });
    setGlobalVariableValue({ key: 'HiSeasonStart', value: hiSeasStart });
    setGlobalVariableValue({ key: 'HiSeasonEnd', value: hiSeasEnd });
    //setGlobalVariableValue({key : 'VillaImgUrl', value: villaImgUrl});

    setTimeout(() => {
      console.log('Variables.VacSeasonId ==> ' + Variables.VacSeasonId);
      console.log('Variables.VacSeasonStart ==> ' + Variables.VacSeasonStart);
      console.log('Variables.VacSeasonEnd ==> ' + Variables.VacSeasonEnd);
    }, 0);
  };
  const isOnline = useIsOnline();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setAppIsOnLine(isOnline);
      isOnlineCtrl(AppIsOnLine);
      const retVacYear = assignVacSeasonYear(Variables, setGlobalVariableValue);
      StatusBar.setBarStyle('light-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['TripItBlue'] },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <ImageBackground
            backfaceVisibility={'visible'}
            resizeMode={'stretch'}
            source={{
              uri: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1677592051/ViVi/vvFond_un0fbw.jpg',
            }}
            style={StyleSheet.applyWidth(
              { height: '100%', justifyContent: 'flex-end', width: '100%' },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: 16, marginRight: 16, marginTop: 64 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Poppins_500Medium_Italic',
                      fontSize: 42,
                      marginLeft: 54,
                    },
                    dimensions.width
                  )}
                >
                  {'VillaViews '}
                </Text>
                <Image
                  resizeMode={'contain'}
                  source={Images.AppLogo}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomLeftRadius: 22,
                      borderBottomWidth: 1,
                      borderColor: theme.colors['Strong_Inverse'],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      borderTopRightRadius: 22,
                      borderTopWidth: 1,
                      height: 50,
                      marginTop: -55,
                      width: 50,
                    },
                    dimensions.width
                  )}
                />
                <Spacer left={8} right={8} bottom={12} top={12} />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    ellipsizeMode={'tail'}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.mediumInverse,
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 30,
                        marginTop: -20,
                      },
                      dimensions.width
                    )}
                    textBreakStrategy={'highQuality'}
                  >
                    {'Croatia here I come'}
                  </Text>

                  <Text
                    accessible={true}
                    ellipsizeMode={'tail'}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.mediumInverse,
                        fontFamily: 'Poppins_400Regular',
                        marginTop: -14,
                        typography: theme.typography.body1,
                      },
                      dimensions.width
                    )}
                    textBreakStrategy={'highQuality'}
                  >
                    {'The best rental properties, curated for you. '}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 159 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong_Inverse'],
                        fontFamily: 'Poppins_500Medium_Italic',
                        fontSize: 20,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Your upcoming best ever vacations\nwill be Croatia'}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  height: 150,
                  marginBottom: 83,
                  paddingBottom: 64,
                  paddingLeft: 24,
                  paddingRight: 24,
                },
                dimensions.width
              )}
            >
              {/* Button Solid */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      const retVillasData = (
                        await VillaVuXanoApi.listAllActiveVillasGET(Constants)
                      )?.json;
                      setGlobalVariableValue({
                        key: 'VillasData',
                        value: retVillasData,
                      });
                      setGlobalVariableValue({
                        key: 'LANG',
                        value: 'EN',
                      });
                      assignVillaVals(Variables, setGlobalVariableValue);
                      const VacSeasonRecs = (
                        await VillaVuXanoApi.getVacSeasonIdGET(Constants, {
                          vacyear: Constants['VacYear'],
                        })
                      )?.json;
                      extractVacSeasonInfo(
                        Variables,
                        setGlobalVariableValue,
                        VacSeasonRecs
                      );
                      navigation.navigate('AllViewsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Strong_Inverse'],
                    borderLeftWidth: 1,
                    borderRadius: 21,
                    borderRightWidth: 3,
                    borderTopWidth: 1,
                    color: theme.colors['Strong_Inverse'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 13,
                    textAlign: 'center',
                    width: '96%',
                  },
                  dimensions.width
                )}
                title={
                  'Get a  closer Villa View\n& reserve your dream vacation'
                }
              />
              <Spacer bottom={8} left={8} right={8} top={8} />
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'center',
                    color: theme.colors['Medium Inverse'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 18,
                    marginTop: 13,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'You can always SignIn\nor SignUp later on within the app'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginTop: 24,
                  },
                  dimensions.width
                )}
              >
                <Button
                  onPress={() => {
                    try {
                      navigation.navigate('SignInScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: theme.colors['Strong'],
                        borderBottomWidth: 2,
                        borderColor: theme.colors['Strong_Inverse'],
                        borderLeftWidth: 1,
                        borderRadius: 21,
                        borderRightWidth: 3,
                        borderTopWidth: 1,
                        fontFamily: 'Poppins_600SemiBold',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Sign In'}
                />
                <Button
                  onPress={() => {
                    try {
                      navigation.navigate('SignUpScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: theme.colors['Strong'],
                        borderBottomWidth: 2,
                        borderColor: theme.colors['Strong_Inverse'],
                        borderLeftWidth: 1,
                        borderRadius: 21,
                        borderRightWidth: 3,
                        borderTopWidth: 1,
                        fontFamily: 'Poppins_600SemiBold',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Sign up'}
                />
              </View>
            </View>
          </ImageBackground>
        )}
      </>
      {/* Image Background 2 */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <ImageBackground
            backfaceVisibility={'visible'}
            resizeMode={'stretch'}
            source={{ uri: `${DfltBkGrnd}` }}
            style={StyleSheet.applyWidth(
              { height: '100%', justifyContent: 'flex-end', width: '100%' },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: 16, marginRight: 16, marginTop: 64 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Poppins_500Medium_Italic',
                      fontSize: 42,
                      marginLeft: 54,
                    },
                    dimensions.width
                  )}
                >
                  {'VillaViews '}
                </Text>
                <Image
                  resizeMode={'contain'}
                  source={Images.AppLogo}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomLeftRadius: 22,
                      borderBottomWidth: 1,
                      borderColor: theme.colors['Strong_Inverse'],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      borderTopRightRadius: 22,
                      borderTopWidth: 1,
                      height: 50,
                      marginTop: -55,
                      width: 50,
                    },
                    dimensions.width
                  )}
                />
                <Spacer left={8} right={8} bottom={12} top={12} />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    ellipsizeMode={'tail'}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.mediumInverse,
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 30,
                        marginTop: -20,
                      },
                      dimensions.width
                    )}
                    textBreakStrategy={'highQuality'}
                  >
                    {'Croatia here I come'}
                  </Text>

                  <Text
                    accessible={true}
                    ellipsizeMode={'tail'}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.mediumInverse,
                        fontFamily: 'Poppins_400Regular',
                        marginTop: -14,
                        typography: theme.typography.body1,
                      },
                      dimensions.width
                    )}
                    textBreakStrategy={'highQuality'}
                  >
                    {'The best rental properties, curated for you. '}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 159 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong_Inverse'],
                        fontFamily: 'Poppins_500Medium_Italic',
                        fontSize: 20,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Your upcoming best ever vacations\nwill be Croatia'}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  height: 150,
                  marginBottom: 83,
                  paddingBottom: 64,
                  paddingLeft: 24,
                  paddingRight: 24,
                },
                dimensions.width
              )}
            >
              {/* Button Solid */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      const retVillasData = (
                        await VillaVuXanoApi.listAllActiveVillasGET(Constants)
                      )?.json;
                      setGlobalVariableValue({
                        key: 'VillasData',
                        value: retVillasData,
                      });
                      setGlobalVariableValue({
                        key: 'LANG',
                        value: 'EN',
                      });
                      assignVillaVals(Variables, setGlobalVariableValue);
                      const VacSeasonRecs = (
                        await VillaVuXanoApi.getVacSeasonIdGET(Constants, {
                          vacyear: Constants['VacYear'],
                        })
                      )?.json;
                      extractVacSeasonInfo(
                        Variables,
                        setGlobalVariableValue,
                        VacSeasonRecs
                      );
                      navigation.navigate('AllViewsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Strong_Inverse'],
                    borderLeftWidth: 1,
                    borderRadius: 21,
                    borderRightWidth: 3,
                    borderTopWidth: 1,
                    color: theme.colors['Strong_Inverse'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 13,
                    textAlign: 'center',
                    width: '96%',
                  },
                  dimensions.width
                )}
                title={
                  'Get a  closer Villa View\n& reserve your dream vacation'
                }
              />
              <Spacer bottom={8} left={8} right={8} top={8} />
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'center',
                    color: theme.colors['Medium Inverse'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 18,
                    marginTop: 13,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'You can always SignIn\nor SignUp later on within the app'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginTop: 24,
                  },
                  dimensions.width
                )}
              >
                <Button
                  onPress={() => {
                    try {
                      navigation.navigate('SignInScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: theme.colors['Strong'],
                        borderBottomWidth: 2,
                        borderColor: theme.colors['Strong_Inverse'],
                        borderLeftWidth: 1,
                        borderRadius: 21,
                        borderRightWidth: 3,
                        borderTopWidth: 1,
                        fontFamily: 'Poppins_600SemiBold',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Sign In'}
                />
                <Button
                  onPress={() => {
                    try {
                      navigation.navigate('SignUpScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: theme.colors['Strong'],
                        borderBottomWidth: 2,
                        borderColor: theme.colors['Strong_Inverse'],
                        borderLeftWidth: 1,
                        borderRadius: 21,
                        borderRightWidth: 3,
                        borderTopWidth: 1,
                        fontFamily: 'Poppins_600SemiBold',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Sign up'}
                />
              </View>
            </View>
          </ImageBackground>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(VillaViewsScreen);
