import React from 'react';
import * as CustomCode from '../CustomCode';
import * as VillaVuXanoApi from '../apis/VillaVuXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Alert, Image, ImageBackground, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CompleteAccountFormScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [isNotValideForm, setIsNotValideForm] = React.useState(true);
  const [isSeen, setIsSeen] = React.useState(true);
  const [isSeen2, setIsSeen2] = React.useState(true);
  const [myEmailAddress, setMyEmailAddress] = React.useState('');
  const [myFirstLastName, setMyFirstLastName] = React.useState('');
  const [myPassword1, setMyPassword1] = React.useState('');
  const [myPassword2, setMyPassword2] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const LoginOrSignupBeforePayment = (setGlobalVariableValue, jsonResponse) => {
    console.log(
      'JsonResponse =token=> ' + JSON.stringify(jsonResponse.authToken)
    );
    //Alert.alert("JsonResponse ==> "+JSON.stringify(jsonResponse));
    if (jsonResponse.authToken) {
      setGlobalVariableValue({
        key: 'AuthToken',
        value: 'Bearer ' + jsonResponse.authToken,
      });
      props.navigation.navigate('PaymentCardInfoScreen');
    }
  };

  const ValidateAccount = Variables => {
    const MESSAGE = {
      fullname: 'Error in full name, try again ...',
      email: 'Error in your email format, try again ...',
      password:
        'Password length sould at least be 8 caracters : letters, digits and at least one Capital letter; try again ...',
    };

    const showMessage = msg => {
      alert(msg);
    };
    const miniValidation = (name, email, password) => {
      // <= Added this function
      const strongRegex = new RegExp(
        '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
      );

      if (name == '') {
        showMessage(MESSAGE.fullname);
        return false;
      } else if (!strongRegex.test(email)) {
        showMessage(MESSAGE.email);
        return false;
      } else if (password.length < 8) {
        showMessage(MESSAGE.password);
        return false;
      }
      return true;
    };

    console.log('ValidateAccount::before validation');
    let validparams = miniValidation(
      myFirstLastName,
      myEmailAddress,
      myPassword1
    );
    if (!validparams) {
      setIsNotValideForm(true);
      return true;
    } else {
      console.log('ValidateAccount::Params validation is ok');
      setIsNotValideForm(false);
      return false;
    }
    //props.navigation.navigate("PaymentCardInfoScreen");
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['myLightBlue'],
          justifyContent: 'center',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.IMG0001}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', marginTop: 45, width: '100%' },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={theme.colors['Strong_Inverse']}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors['Strong_Inverse'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  marginTop: 11,
                },
                dimensions.width
              )}
            >
              {'Complete account'}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          ></View>
        </View>
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, justifyContent: 'center', marginTop: 5 },
            dimensions.width
          )}
        >
          {/* Timeline */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginBottom: 16,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
                width: '100%',
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 16,
                  marginRight: 16,
                  paddingBottom: 13,
                  paddingLeft: 16,
                  paddingRight: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Strong'],
                    borderLeftWidth: 2,
                    borderRadius: 6,
                    borderRightWidth: 2,
                    borderTopWidth: 2,
                    height: 12,
                    width: 12,
                  },
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    flex: 1,
                    height: 2,
                  },
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Strong'],
                    borderLeftWidth: 2,
                    borderRadius: 6,
                    borderRightWidth: 2,
                    borderTopWidth: 2,
                    height: 12,
                    width: 12,
                  },
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong'],
                    flex: 1,
                    height: 2,
                  },
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Strong'],
                    borderLeftWidth: 2,
                    borderRadius: 6,
                    borderRightWidth: 2,
                    borderTopWidth: 2,
                    height: 12,
                    width: 12,
                  },
                  dimensions.width
                )}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: -10,
                  paddingLeft: 10,
                  paddingRight: 10,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 10,
                    textTransform: 'uppercase',
                  },
                  dimensions.width
                )}
              >
                {'personal info'}
              </Text>

              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 10,
                    textTransform: 'uppercase',
                  },
                  dimensions.width
                )}
              >
                {'payment'}
              </Text>

              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 10,
                    textTransform: 'uppercase',
                  },
                  dimensions.width
                )}
              >
                {'Booking ticket'}
              </Text>
            </View>
          </View>
        </View>

        <KeyboardAwareScrollView
          enableAutomaticScroll={false}
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
          viewIsInsideTabBar={false}
          contentContainerStyle={StyleSheet.applyWidth(
            { zIndex: 99 },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, paddingLeft: 20, paddingRight: 20, zIndex: 101 },
              dimensions.width
            )}
          >
            {/* First Last Name */}
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 13, opacity: 0.85 },
                dimensions.width
              )}
            >
              {/* First Last Name */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    marginLeft: 11,
                    marginTop: 2,
                  },
                  dimensions.width
                )}
              >
                {'Full Name'}
              </Text>
              {/* First Last Name Input */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newFirstLastNameInputValue => {
                  try {
                    setMyFirstLastName(newFirstLastNameInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                editable={true}
                placeholder={'Enter first & last name'}
                placeholderTextColor={theme.colors['Light']}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong_Inverse'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors['MyBlack'],
                    borderLeftWidth: 1,
                    borderRadius: 5,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: theme.colors['MyBlack'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 16,
                    height: 46,
                    marginBottom: 4,
                    marginLeft: 11,
                    marginTop: 4,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '92%',
                  },
                  dimensions.width
                )}
                value={myFirstLastName}
              />
            </View>
            {/* Your Email */}
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 13, marginTop: 5, opacity: 0.85 },
                dimensions.width
              )}
            >
              {/* Your Email */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    marginLeft: 11,
                    marginTop: 2,
                  },
                  dimensions.width
                )}
              >
                {'Your Email'}
              </Text>
              {/* Your Email Input */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newYourEmailInputValue => {
                  try {
                    setMyEmailAddress(newYourEmailInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                editable={true}
                keyboardType={'email-address'}
                placeholder={'Enter your login email'}
                placeholderTextColor={theme.colors['Light']}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Strong_Inverse'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors['MyBlack'],
                    borderLeftWidth: 1,
                    borderRadius: 5,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: theme.colors['MyBlack'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 16,
                    height: 46,
                    marginBottom: 4,
                    marginLeft: 11,
                    marginTop: 4,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '92%',
                  },
                  dimensions.width
                )}
                textContentType={'emailAddress'}
                value={myEmailAddress}
              />
            </View>
            {/* Password */}
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 13, marginTop: 5, opacity: 0.85 },
                dimensions.width
              )}
            >
              {/* Password */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    marginLeft: 11,
                    marginTop: 2,
                  },
                  dimensions.width
                )}
              >
                {'Password'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginTop: 12,
                    width: '94%',
                  },
                  dimensions.width
                )}
              >
                {/* Pasword Input */}
                <>
                  {!isSeen ? null : (
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newPaswordInputValue => {
                        try {
                          setMyPassword1(newPaswordInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      editable={true}
                      placeholder={'Enter/Create your password'}
                      placeholderTextColor={theme.colors['Light']}
                      secureTextEntry={true}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Strong_Inverse'],
                          borderBottomWidth: 1,
                          borderColor: theme.colors['MyBlack'],
                          borderLeftWidth: 1,
                          borderRadius: 5,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          color: theme.colors['MyBlack'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 16,
                          height: 46,
                          marginBottom: 4,
                          marginLeft: 11,
                          marginTop: 4,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          paddingRight: 8,
                          paddingTop: 8,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                      value={myPassword1}
                    />
                  )}
                </>
                {/* Pasword1ShowInputText */}
                <>
                  {isSeen ? null : (
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newPasword1ShowInputTextValue => {
                        try {
                          setMyPassword1(newPasword1ShowInputTextValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      editable={true}
                      placeholder={'Create a password'}
                      placeholderTextColor={theme.colors['Light']}
                      secureTextEntry={false}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Custom Color_4'],
                          borderLeftWidth: 1,
                          borderRadius: 5,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          color: theme.colors['MyBlack'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 16,
                          height: 46,
                          marginBottom: 4,
                          marginLeft: 11,
                          marginTop: 4,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          paddingRight: 8,
                          paddingTop: 8,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                      value={myPassword1}
                    />
                  )}
                </>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      height: 56,
                      justifyContent: 'center',
                      position: 'absolute',
                      right: 0,
                      width: 48,
                      zIndex: 1,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setIsSeen(!isSeen);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Icon size={24} name={'Feather/eye'} />
                  </Touchable>
                </View>
              </View>
            </View>

            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'flex-start',
                  color: theme.colors['Strong'],
                  fontFamily: 'Poppins_400Regular',
                  marginLeft: 10,
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              {'Login or Create account'}
            </Text>
            {/* Continue to payment */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    const _notValide = ValidateAccount(Variables);
                    const _LognSignRes = (
                      await VillaVuXanoApi.loginOrSignupPOST(Constants, {
                        lsEmail: myEmailAddress,
                        lsName: myFirstLastName,
                        lsPassword: myPassword1,
                      })
                    )?.json;
                    LoginOrSignupBeforePayment(
                      setGlobalVariableValue,
                      _LognSignRes
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong'],
                  borderBottomWidth: 2,
                  borderColor: theme.colors['Strong_Inverse'],
                  borderLeftWidth: 1,
                  borderRadius: 24,
                  borderRightWidth: 2,
                  borderTopWidth: 1,
                  color: theme.colors['Strong_Inverse'],
                  fontFamily: 'Poppins_600SemiBold',
                  fontSize: 16,
                  height: 49,
                  marginLeft: '35%',
                  marginTop: 15,
                  textAlign: 'center',
                  width: '65%',
                },
                dimensions.width
              )}
              title={'Continue to payment'}
            />
            {/* Reset Password */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 45,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 16,
                    opacity: 0.9,
                  },
                  dimensions.width
                )}
              >
                {'Forgot your passwordt? '}
              </Text>

              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('ResetPasswordScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 16,
                      textDecorationLine: 'underline',
                    },
                    dimensions.width
                  )}
                >
                  {'Reset It here'}
                </Text>
              </Touchable>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(CompleteAccountFormScreen);
