import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as VillaVuXanoApi from '../apis/VillaVuXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Image, ImageBackground, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const SignUpScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [inEmail, setInEmail] = React.useState('');
  const [inName, setInName] = React.useState('');
  const [inPswd, setInPswd] = React.useState('');
  const [respError, setRespError] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['TripItBlue'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.IMG0001}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* FullNavigationFrame */}
        <View
          {...GlobalStyles.ViewStyles(theme)['fullNavigationFrame'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['fullNavigationFrame'].style,
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={theme.colors['Strong_Inverse']}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors['Strong_Inverse'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  letterSpacing: 2,
                },
                dimensions.width
              )}
            >
              {Constants['currSectionData']?.SectionTitle}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          ></View>
        </View>

        <KeyboardAwareScrollView
          enableAutomaticScroll={false}
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
          viewIsInsideTabBar={false}
          contentContainerStyle={StyleSheet.applyWidth(
            {
              justifyContent: 'center',
              paddingBottom: 48,
              paddingLeft: 48,
              paddingRight: 48,
              paddingTop: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.AppLogo}
              style={StyleSheet.applyWidth(
                {
                  borderBottomLeftRadius: 22,
                  borderTopRightRadius: 22,
                  height: 47,
                  width: 47,
                },
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 28,
                  marginLeft: 10,
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              {'VillaViews'}
            </Text>
          </View>
          <Spacer bottom={8} left={8} right={8} top={18} />
          <View>
            <Spacer left={8} right={8} bottom={4} top={18} />
          </View>
          <Spacer left={8} right={8} bottom={24} top={24} />
          <View>
            {/* Name */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'Name'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Strong'],
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Strong']}
                  name={'Feather/user'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  {/* Text Input name */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputNameValue => {
                      try {
                        setInName(newTextInputNameValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    keyboardType={'default'}
                    placeholder={'Full name'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Strong_Inverse'],
                        borderRadius: 3,
                        color: theme.colors['Strong'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 18,
                        paddingLeft: 9,
                      },
                      dimensions.width
                    )}
                    value={inName}
                  />
                </View>
              </View>
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
            {/* Email */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'Email'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Strong'],
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Strong']}
                  name={'MaterialIcons/alternate-email'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  {/* Text Input email */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputEmailValue => {
                      try {
                        setInEmail(newTextInputEmailValue);
                        setRespError('');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    keyboardType={'email-address'}
                    placeholder={'yourname@email.com'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Strong_Inverse'],
                        borderRadius: 3,
                        color: theme.colors['Strong'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 18,
                        paddingLeft: 9,
                      },
                      dimensions.width
                    )}
                    value={inEmail}
                  />
                </View>
              </View>
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
            {/* Password */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'Password'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Strong'],
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Strong']}
                  name={'MaterialIcons/lock-outline'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  {/* Text Input pswd */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputPswdValue => {
                      try {
                        setInPswd(newTextInputPswdValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    keyboardType={'default'}
                    placeholder={'8 Letters & digits minimum'}
                    placeholderTextColor={theme.colors['Light']}
                    secureTextEntry={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Strong_Inverse'],
                        borderRadius: 3,
                        color: theme.colors['Strong'],
                        fontFamily: 'System',
                        fontSize: 18,
                        fontWeight: '400',
                        paddingLeft: 9,
                      },
                      dimensions.width
                    )}
                    value={inPswd}
                  />
                </View>
              </View>
            </View>
          </View>

          <Spacer left={8} right={8} bottom={32} top={32}>
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    alignSelf: 'center',
                    color: theme.colors['Primary'],
                    fontFamily: 'Poppins_500Medium',
                  }
                ),
                dimensions.width
              )}
            >
              {respError}
            </Text>
          </Spacer>

          <View>
            {/* Button Solid */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    const _signupRes = (
                      await VillaVuXanoApi.signupPOST(Constants, {
                        signupEmail: inEmail,
                        signupName: inName,
                        signupPassword: inPswd,
                      })
                    )?.json;
                    console.log(_signupRes);
                    const _authToken = _signupRes?.authToken;
                    const _message = _signupRes?.message;
                    setRespError(_message);
                    if (!_authToken) {
                      return;
                    }
                    setGlobalVariableValue({
                      key: 'AuthToken',
                      value: 'Bearer ' + _authToken,
                    });
                    console.log(Constants['AuthToken']);
                    const _usrRecord = (
                      await VillaVuXanoApi.authMeGET(Constants)
                    )?.json;
                    console.log(_usrRecord);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong'],
                  borderBottomWidth: 2,
                  borderColor: theme.colors.mediumInverse,
                  borderLeftWidth: 1,
                  borderRadius: 44,
                  borderRightWidth: 3,
                  borderTopWidth: 1,
                  color: theme.colors.surface,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 16,
                  paddingBottom: 16,
                  paddingTop: 16,
                  textAlign: 'center',
                },
                dimensions.width
              )}
              title={'Sign Up'}
            />
            <Spacer left={8} right={8} bottom={12} top={12} />
            <View
              style={StyleSheet.applyWidth(
                { alignSelf: 'center' },
                dimensions.width
              )}
            >
              <Link
                accessible={true}
                onPress={() => {
                  try {
                    navigation.navigate('SignInScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Already have an account? Sign in'}
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(SignUpScreen);
