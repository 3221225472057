// This import is required if you are defining react components in this module.
import React from 'react';

import { Spacer, Touchable } from '@draftbit/ui';

import { Image, Text, View, Dimensions } from 'react-native';

import * as GetSwiperSectorStruct from '../custom-files/GetSwiperSectorStruct';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const FillJsx = props => {
  const { theme } = props;
  const { navigation } = props;
  const { sectId } = props;

  const { height, width } = Dimensions.get('window');

  let imgWd = 308;
  let imgHt = 210;
  let maxWd = 400;
  let maxHt = 260;
  if (width >= 800) {
    imgWd = 580;
    imgHt = 380;
    maxWd = 600;
    maxHt = 420;
  }

  let sectObj = GetSwiperSectorStruct.SectorData(sectId);
  let sectorData = sectObj.sectorData;
  const sectTitle = sectObj.sectorData.SectorScrollTitle; //"Villa terraces & Views";
  const sectImgUrl = sectObj.sectorData.SectorScrollImgUrl;

  //const sectId = 1;
  //const sectTitle = "Villa terraces & Views";
  //const sectImgUrl = 'https://res.cloudinary.com/dcmydoriy/image/upload/v1675906567/ViVi/IMG_2084_tzptxh.jpg';

  //const Constants = GlobalVariables.useValues();
  //const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  function setNuSlctdSectorParams(setGlobalVariableValue, sectId) {
    let sectObj = GetSwiperSectorStruct.SectorData(sectId);
    let sectorData = sectObj.sectorData;
    console.log(
      'setSlctdSectorParams::sectorData.SectorTitle ==> ',
      sectorData.SectorTitle
    );
    setGlobalVariableValue({
      key: 'slctdSectorTitle',
      value: sectorData.SectorTitle,
    });
    setGlobalVariableValue({ key: 'slctdSectorId', value: sectorData.id });
    // prep Variables.SectionsImgs variable
    let SectionsData = [];
    for (var i = 0; i < sectorData.Sections.length; i++) {
      let sctnObj = {};
      sctnObj.id = sectorData.Sections[i].id;
      sctnObj.title = sectorData.Sections[i].SectionScrollTitle;
    }
    setGlobalVariableValue({
      key: 'indoorsList',
      value: sectorData.Sections[0].SectionItems,
    }); //1st section shown by  default
  }

  return (
    <View
      style={{
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: theme.colors['TripItBlue'],
        borderBottomWidth: 1,
        borderColor: theme.colors['Strong_Inverse'],
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        height: maxHt,
        marginTop: 15,
        maxWidth: maxWd,
        opacity: 1,
        width: '85%',
      }}
    >
      <Touchable
        onPress={() => {
          try {
            setGlobalVariableValue({
              key: 'slctdSectorId',
              value: sectId,
            });
            setNuSlctdSectorParams(setGlobalVariableValue, sectId);
            //navigation.navigate('SwiperSectorExplorerScreen');
            navigation.navigate('SectionsMasonryListScreen');
          } catch (err) {
            console.error(err);
          }
        }}
      >
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '96%',
            paddingBottom: 8,
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 12,
          }}
        >
          <View
            style={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              flex: 1,
              flexDirection: 'row',
              height: imgHt,
              width: imgWd,
            }}
          >
            <Image
              style={{
                borderRadius: 5,
                alignSelf: 'center',
                height: imgHt,
                width: imgWd,
              }}
              resizeMode={'cover'}
              source={{ uri: sectImgUrl }}
            />
          </View>
          <Spacer top={4} right={8} bottom={0} left={8} />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 2,
            }}
          >
            <Text
              style={{
                color: theme.colors['Strong_Inverse'],
                fontFamily: 'Poppins_500Medium',
                fontSize: 18,
              }}
            >
              {sectTitle}
            </Text>
          </View>
        </View>
      </Touchable>
    </View>
  );
};
