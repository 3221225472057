import React from 'react';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ExpoFileSys from '../custom-files/ExpoFileSys';
import * as Wait4AssetsLoadBeforeWelcome from '../custom-files/Wait4AssetsLoadBeforeWelcome';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Sharing from 'expo-sharing';
import { ActivityIndicator, Image, Text, View } from 'react-native';

const VillaViewsAnimScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [navFired, setNavFired] = React.useState(false);
  const [showImg1, setShowImg1] = React.useState(true);
  const [showImg2, setShowImg2] = React.useState(true);
  const [showImg3, setShowImg3] = React.useState(true);
  const [showImg4, setShowImg4] = React.useState(false);
  const _trySharingPhoto = async () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    let img1 = Images.IMG2084;

    const fileUri = ExpoFileSys.cacheDirectory + 'tmp.jpg';
    const imgUrl = 'https://i.imgur.com/B8rw1pu.jpeg'; //'https://imgur.com/FDbumx3';

    var canShare = false;
    Sharing.isAvailableAsync().then(available => {
      if (available) {
        canShare = true;
        console.log('Sharing is available');
      } else {
        canShare = false;
        console.log('Sharing is NOT available');
      }
    });

    if (canShare == true) {
      console.log('Now ==> Sharing');
      const options = {
        mimeType: 'image/jpeg',
        dialogTitle: 'Share the image',
        UTI: 'image/jpeg',
      };

      ExpoFileSys.downloadAsync(imgUrl, fileUri)
        .then(({ uri }) => {
          console.log(`Downloaded image to ${uri}`);
        })
        .catch(err => {
          console.log('Error downloading image');
          console.log(JSON.stringify(err));
        });

      // Sharing only allows one to share a file.
      Sharing.shareAsync(fileUri, options)
        .then(data => {
          console.log('Shared');
        })
        .catch(err => {
          console.log('Error sharing image');
          console.log(JSON.stringify(err));
        });
    }
  };

  const goShowImgs = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    setTimeout(() => {
      setShowImg1(false);
      setShowImg2(true);
      setShowImg3(false);
      setShowImg4(true);
      setTimeout(() => {
        setShowImg1(true);
        setShowImg2(false);
        setShowImg3(true);
        setShowImg4(false);
        setTimeout(() => {
          setShowImg1(true);
          setShowImg2(true);
          setShowImg3(true);
          setShowImg4(true);

          setTimeout(() => {
            //navigation.navigate("WelcomeScreen");
            navigation.navigate('AuthStack', { screen: 'VillaViewsScreen' });
          }, 1357);
        }, 1357);
      }, 1357);
    }, 1357);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      goShowImgs();
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['myLightBlue'] },
        dimensions.width
      )}
    >
      {/* View Anim Timers */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            borderColor: theme.colors['MyBlack'],
            borderStyle: 'dotted',
            height: 300,
            justifyContent: 'center',
            top: '33%',
            width: 300,
          },
          dimensions.width
        )}
      >
        <ActivityIndicator
          animating={true}
          hidesWhenStopped={true}
          {...GlobalStyles.ActivityIndicatorStyles(theme)['Activity Indicator']
            .props}
          color={theme.colors['Strong']}
          size={'large'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ActivityIndicatorStyles(theme)['Activity Indicator']
                .style,
              { marginBottom: 15, marginLeft: 5, marginRight: 5, marginTop: 35 }
            ),
            dimensions.width
          )}
        />
        <Utils.CustomCodeErrorBoundary>
          <Wait4AssetsLoadBeforeWelcome.WaitSecs
            navigation={props.navigation}
            wSecs={7000}
            setShowImg1={setShowImg1}
            setShowImg2={setShowImg2}
            setShowImg3={setShowImg3}
            setShowImg4={setShowImg4}
            navFired={navFired}
            setNavFired={setNavFired}
          />
        </Utils.CustomCodeErrorBoundary>
      </View>
      <>
        {!showImg1 ? null : (
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={Images.AppLogo}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  borderBottomLeftRadius: 47,
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Strong_Inverse'],
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopRightRadius: 47,
                  borderTopWidth: 1,
                  height: 250,
                  left: '10%',
                  position: 'absolute',
                  top: 45,
                  width: 250,
                }
              ),
              dimensions.width
            )}
          />
        )}
      </>
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'center' },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row' },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('AuthStack', {
                  screen: 'VillaViewsScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              { borderColor: theme.colors['Strong_Inverse'] },
              dimensions.width
            )}
          >
            <>
              {!showImg2 ? null : (
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={Images.AppLogo}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderBottomLeftRadius: 22,
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Strong_Inverse'],
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        borderTopRightRadius: 22,
                        borderTopWidth: 1,
                        height: 50,
                        marginLeft: 25,
                        marginTop: 25,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
          </Touchable>
        </View>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontFamily: 'Poppins_400Regular_Italic',
              fontSize: 66,
            }),
            dimensions.width
          )}
        >
          {'V'}
        </Text>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontFamily: 'Poppins_500Medium_Italic',
              fontSize: 42,
              marginTop: 25,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'illaViews'}
        </Text>
      </View>
      <>
        {showImg4 ? null : (
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={Images.AppLogo}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  borderBottomLeftRadius: 29,
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Strong_Inverse'],
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopRightRadius: 29,
                  borderTopWidth: 1,
                  left: '65%',
                  marginTop: 11,
                }
              ),
              dimensions.width
            )}
          />
        )}
      </>
      <>
        {!showImg3 ? null : (
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={Images.AppLogo}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  borderBottomLeftRadius: 38,
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Strong_Inverse'],
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopRightRadius: 38,
                  borderTopWidth: 1,
                  height: 180,
                  marginLeft: '35%',
                  marginTop: '20%',
                  width: 180,
                }
              ),
              dimensions.width
            )}
          />
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(VillaViewsAnimScreen);
