// This import is required if you are defining react components in this module.
import React from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { View, Text, Dimensions, Platform } from 'react-native';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <SetSectionTitle.SectionTitle />
export const SectionTitle = props => {
  const { theme } = props;
  const { slctdTtr } = props;

  let sectionTitle = slctdTtr;
  let mrgTp = 275;
  if (Platform.OS === 'ios') mrgTp = 315;
  if (Platform.OS === 'android') mrgTp = 275;
  const { height, width } = Dimensions.get('window');
  if (width > height) {
    if (height > 900) {
      // Big tablette or PC screen
      mrgTp = 240;
    }
    if (width > 1180) {
      //hide title for very wide screens; it sits on image so hide.
      sectionTitle = '';
    }
  }
  //alert("width ==> "+width+" height ==> "+height);
  return (
    <View style={{ position: 'absolute', marginTop: mrgTp }}>
      <Text
        style={{
          fontFamily: 'Poppins_600SemiBold',
          fontSize: 20,
          letterSpacing: 4,
          color: theme.colors['Strong'],
        }}
      >
        {sectionTitle}
      </Text>
    </View>
  );
};
